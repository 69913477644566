import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  styled,
  Box,
} from '@mui/material';
import axios from 'axios';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: 'auto',
  marginBottom: theme.spacing(2),
}));

const StyledCardMedia = styled(CardMedia)({
  height: 140,
});

const ExhibitorResourceCenter = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    axios
      .get(`/rotr/exhibitors/resourceCenterContent`)
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  return (
    <Box sx={{ padding: '16px' }}>
      <Grid container justifyContent='center'>
        <Grid item container xs={12} md={12} id='EXHIBITOR_RESOURCE_ROW1_COL1' />
        <StyledContainer>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <StyledCard>
                <StyledCardMedia
                  image='https://via.placeholder.com/345x140'
                  title='Exhibitor Manual'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Exhibitor Manual
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Download the exhibitor manual for detailed information on booth setup,
                    regulations, and more.
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledCard>
                <StyledCardMedia
                  image='https://via.placeholder.com/345x140'
                  title='Marketing Materials'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Marketing Materials
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Access marketing materials to help you promote your presence at the event to
                    your audience.
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledCard>
                <StyledCardMedia
                  image='https://via.placeholder.com/345x140'
                  title='Booth Staff Guidelines'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Booth Staff Guidelines
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Ensure your booth staff are well-prepared by reviewing the guidelines and best
                    practices.
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledCard>
                <StyledCardMedia
                  image='https://via.placeholder.com/345x140'
                  title='Lead Retrieval'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Lead Retrieval
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Learn about the lead retrieval services available to help you capture and follow
                    up with potential clients.
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>
        </StyledContainer>
      </Grid>
    </Box>
  );
};

export default ExhibitorResourceCenter;
