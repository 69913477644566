import React from 'react';
import ReactDOMServer from 'react-dom/server';

const InvoiceEmailLayout = ({ details }) => {
  const { action, firstName, booth, exhibitorName, accessCode, link } = details;

  const content = (
    <div style={{ fontFamily: 'Arial, sans-serif', color: '#333', lineHeight: '1.5' }}>
      <p>{firstName},</p>
      {action === 'add' ? (
        <p style={{ maxWidth: '500px', textAlign: 'justify' }}>
          Thank you for registering for Rendezvous on the Red! We have confirmed booth{' '}
          <strong>{booth}</strong> for{' '}
          <strong>
            {exhibitorName.endsWith('.') ? exhibitorName.slice(0, -1) : exhibitorName}
          </strong>
          . To make changes to your booth selection, add-ons, profile, or booth personnel, please
          use the link below.
        </p>
      ) : (
        <p style={{ maxWidth: '500px', textAlign: 'justify' }}>
          Thank you for updating your registration for Rendezvous on the Red! Your booth{' '}
          <strong>{booth}</strong> for <strong>{exhibitorName}</strong> has been successfully
          modified. To make further changes to your booth selection, add-ons, profile, or booth
          personnel, please use the link below.
        </p>
      )}
      <p>
        For convenience, your Invitation Code is: <strong>{accessCode}</strong>
      </p>
      <a
        href={link}
        style={{
          display: 'inline-block',
          padding: '10px 20px',
          margin: '20px 0',
          fontSize: '16px',
          color: '#fff',
          backgroundColor: '#00325a',
          textDecoration: 'none',
          borderRadius: '5px',
          textAlign: 'center',
        }}
      >
        Manage Booth
      </a>
    </div>
  );

  return ReactDOMServer.renderToStaticMarkup(content);
};

export default InvoiceEmailLayout;
