import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  Box,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#f9f9f9',
  boxShadow: theme.shadows[1],
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#cb351a',
  color: '#fff',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#fff',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#fff',
}));

const AttendeeFAQ = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    axios
      .get(`/rotr/attendees/faqContent`)
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  return (
    <Box sx={{ padding: '16px' }}>
      <Grid container justifyContent='center'>
        <Grid item container xs={12} id='ATTENDEE_FAQ_ROW1_COL1' />
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <StyledContainer>
            <StyledAccordion>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6'>How do I register for the event?</Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <Typography>
                  You can register for the event by clicking on the 'Register' button on the event
                  page and filling out the registration form.
                </Typography>
              </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6'>What is the event schedule?</Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <Typography>
                  The event schedule can be found on the event website under the 'Schedule' section.
                  It includes all the sessions and workshops.
                </Typography>
              </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6'>Where is the event located?</Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <Typography>
                  The event is located at the Downtown Convention Center. Please refer to the
                  'Location' section on the event website for more details.
                </Typography>
              </StyledAccordionDetails>
            </StyledAccordion>
            {/* Add more FAQ items as needed */}
          </StyledContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AttendeeFAQ;
