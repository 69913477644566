import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import { Grid, Typography } from '@mui/material';

const Countdown = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(duration);
  const eventTime = dayjs.tz('2025-06-16 08:00', 'America/Chicago');

  const calculateTimeLeft = useCallback(() => {
    const now = dayjs();
    const timeDifference = eventTime.diff(now);

    if (timeDifference > 0) {
      const duration = dayjs.duration(timeDifference);
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      return {
        days: days.toString().padStart(3, '0'),
        hours: hours.toString().padStart(2, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        seconds: seconds.toString().padStart(2, '0'),
      };
    } else {
      return {
        days: '000',
        hours: '00',
        minutes: '00',
        seconds: '00',
      };
    }
  }, [eventTime]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  return (
    <>
      <Grid container spacing={1} alignItems='center' justifyContent='center' wrap='nowrap'>
        {Object.entries(timeLeft).map(([unit, value], index) => (
          <React.Fragment key={unit}>
            <Grid item>
              <Typography
                variant='overline'
                component='span'
                sx={{ color: '#cb351a', fontSize: '1.1rem', fontWeight: 'bold' }}
              >
                {value}
              </Typography>
              <Typography variant='overline' sx={{ color: '#00325a', fontSize: '0.7rem' }}>
                {unit.charAt(0).toUpperCase() + unit.slice(1)}
              </Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Grid
        container
        justifyContent='center'
        alignItems='baseline'
        wrap='nowrap'
        spacing={1}
        sx={{ mt: -2 }}
      >
        <Typography
          variant='overline'
          sx={{
            color: '#00325a',
            fontSize: '.9rem',
            fontWeight: 'bold',
          }}
        >
          June
        </Typography>
        <Typography
          variant='overline'
          component='span'
          sx={{
            color: '#cb351a',
            fontSize: '1em',
            fontWeight: 'bold',
            ml: 0.5,
            mr: 0.5,
          }}
        >
          16-18
        </Typography>
        <Typography
          variant='overline'
          sx={{
            color: '#00325a',
            fontSize: '1rem',
            fontWeight: 'bold',
          }}
        >
          2025
        </Typography>
      </Grid>
    </>
  );
};

export default Countdown;
