import { createSlice } from '@reduxjs/toolkit';

export const StateRefreshSlice = createSlice({
  name: 'registrationTable',
  initialState: {
    value: false,
  },
  reducers: {
    setRegistrationTable: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setRegistrationTable } = StateRefreshSlice.actions;
export default StateRefreshSlice.reducer;
