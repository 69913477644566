import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, Typography, Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import banner from '../../Assets/banner.png';
import RegistrationForm from './RegistrationForm';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setModalStatus } from '../../../Redux/ModalSlice';

const RegistrationContainer = () => {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const isRegistered = useSelector((state) => state.attendeeRegistration.isRegistered);
  const dispatch = useDispatch();

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  const handleAddBadgeClick = () => {
    dispatch(setModalStatus({ attendeeBadges: true }));
  };

  useEffect(() => {
    setShowForm(false);
  }, [isRegistered]);

  return (
    <>
      {!isRegistered && showForm && (
        <Paper
          elevation={3}
          sx={{
            p: 2,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: '#f8f9fa',
          }}
        >
          <Paper sx={{ p: 2, borderRadius: 2 }}>
            <Grid container sx={{ textAlign: 'center' }}>
              <Grid item xs={12}>
                <RegistrationForm />
              </Grid>
            </Grid>
          </Paper>
        </Paper>
      )}
      {!isRegistered && !showForm && (
        <Box
          sx={{
            backgroundImage: `url(${banner})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            minHeight: '30vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 4,
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Paper
            elevation={8}
            sx={{
              p: 4,
              borderRadius: 2,
              backgroundColor: alpha('#f8f9fa', 0.8),
              width: {
                xs: '90%',
                sm: '70%',
                md: '50%',
                lg: '40%',
              },
              textAlign: 'center',
            }}
          >
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              direction='column'
              sx={{ mt: 2, mb: 2 }}
            >
              <Grid item>
                <Typography
                  variant='h4'
                  gutterBottom
                  sx={{ fontWeight: 'bold', lineHeight: 1.2, color: '#00325a' }}
                >
                  Welcome to Shreveport
                </Typography>
                <Typography
                  variant='body2'
                  gutterBottom
                  sx={{ fontWeight: 'bold', lineHeight: 1.2, color: '#00325a' }}
                >
                  Click the button below to start registering for the event.
                </Typography>
                <Button
                  variant='contained'
                  onClick={handleShowForm}
                  sx={{
                    backgroundColor: '#00325a',
                    '&:hover': {
                      backgroundColor: alpha('#00325a', 0.6),
                    },
                    mt: 2,
                  }}
                >
                  Start Registration
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
      {isRegistered && (
        <Box
          sx={{
            backgroundImage: `url(${banner})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            minHeight: '30vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 4,
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Paper
            elevation={8}
            sx={{
              p: 4,
              borderRadius: 2,
              backgroundColor: alpha('#f8f9fa', 0.8),
              width: {
                xs: '90%',
                sm: '70%',
                md: '50%',
                lg: '40%',
              },
              textAlign: 'center',
            }}
          >
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              direction='column'
              sx={{ mt: 2, mb: 2 }}
            >
              <Grid item>
                <Typography
                  variant='h4'
                  gutterBottom
                  sx={{ fontWeight: 'bold', lineHeight: 1.2, color: '#00325a' }}
                >
                  Thank You For Registering
                </Typography>
                <Typography
                  variant='body1'
                  gutterBottom
                  sx={{ fontWeight: 'bold', lineHeight: 1.2, color: '#00325a' }}
                >
                  We look forward to seeing you. Don't forget to register your attendees and book
                  your hotel.
                </Typography>
                <Button
                  variant='contained'
                  onClick={handleAddBadgeClick}
                  sx={{
                    backgroundColor: '#00325a',
                    '&:hover': {
                      backgroundColor: alpha('#00325a', 0.6),
                    },
                    mt: 2,
                    mr: 2,
                  }}
                >
                  Add Badges
                </Button>
                <Button
                  variant='contained'
                  onClick={() => navigate('/hotels')}
                  sx={{
                    backgroundColor: '#00325a',
                    '&:hover': {
                      backgroundColor: alpha('#00325a', 0.6),
                    },
                    mt: 2,
                  }}
                >
                  Book Hotel
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default RegistrationContainer;
