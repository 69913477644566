import { createSlice } from '@reduxjs/toolkit';

export const ModalSlice = createSlice({
  name: 'modals',
  initialState: {
    isAddingBooth: false,
    boothModal: false,
    boothModalButton: true,
    confirmModal: false,
    badgeModal: false,
    attendeeBadges: false,
    exhibitorBadges: false,
    profileExplanation: false,
  },
  reducers: {
    setModalStatus: (state, action) => {
      return { ...state, ...action.payload };
    },

    clearModalStatus: (state) => {
      return {
        isAddingBooth: false,
        boothModal: false,
        confirmModal: false,
        badgeModal: false,
        attendeeBadges: false,
        exhibitorBadges: false,
        profileExplanation: false,
      };
    },
  },
});

export const { setModalStatus, clearModalStatus } = ModalSlice.actions;
export default ModalSlice.reducer;
