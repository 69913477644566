import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, alpha } from '@mui/material';

const Ads = ({ numberToShow, gridXS, gridSM, gridMD, gridLG }) => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    axios
      .get('/rotr/ads', {
        params: {
          numberToShow: numberToShow,
        },
      })
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [numberToShow]);

  return (
    <>
      {content.map((ad, index) => (
        <Grid
          item
          sx={{ width: '100%' }}
          xs={gridXS}
          sm={gridSM || gridXS}
          md={gridMD || gridXS}
          lg={gridLG || gridMD || gridXS}
          key={index}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              borderRadius: 1,
              overflow: 'hidden',
              boxShadow: 1,
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(ad.banner_link_url, '_blank');
            }}
          >
            <Box
              component='img'
              src={ad.banner_location_url}
              alt={ad.banner_id}
              sx={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                display: 'block',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                width: '10%',
                maxWidth: '30px',
                aspectRatio: '1 / 1',
                bgcolor: alpha('#000', 0.5),
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxSizing: 'border-box',
              }}
            >
              <Typography
                variant='caption'
                sx={{
                  fontFamily: 'League Spartan',
                  fontSize: '1rem',
                  display: 'block',
                  lineHeight: 1,
                  marginBottom: -0.5,
                }}
              >
                Ad
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </>
  );
};

export default Ads;
