import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  Box,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#f9f9f9',
  boxShadow: theme.shadows[1],
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#cb351a',
  color: '#fff',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#fff',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#fff',
}));

const ExhibitorFAQ = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    axios
      .get(`/rotr/exhibitors/faqContent`)
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  return (
    <Box sx={{ padding: '16px' }}>
      <Grid container justifyContent='center'>
        <Grid item container xs={12} id='EXHIBITOR_FAQ_ROW1_COL1' />
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <StyledContainer>
            <StyledAccordion>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6'>How do I become an exhibitor?</Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <Typography>
                  To become an exhibitor, you need to fill out the exhibitor application form on the
                  event website and submit it for approval.
                </Typography>
              </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6'>What are the booth setup requirements?</Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <Typography>
                  The booth setup requirements are detailed in the exhibitor manual, which you can
                  download from the event website.
                </Typography>
              </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6'>
                  Can I bring additional staff to help at my booth?
                </Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <Typography>
                  Yes, you can bring additional staff to help at your booth. Please register them as
                  booth staff in the exhibitor portal.
                </Typography>
              </StyledAccordionDetails>
            </StyledAccordion>
            {/* Add more FAQ items as needed */}
          </StyledContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExhibitorFAQ;
