import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography } from '@mui/material';
import axios from 'axios';
import UserInfo from '../UserInfo';
import ERegistrationTable from './ERegistrationTable';
import { useDispatch, useSelector } from 'react-redux';
import RegistrationContainer from './RegistrationContainer';
import { setRegistrationTable } from '../../../Redux/StateRefreshSlice';
import { setExhibitors } from '../../../Redux/ExhibitorSlice';
import EmbeddedVideo from '../../../_GlobalComponents/EmbeddedVideo';
import Prompt from '../../../_GlobalComponents/Prompt';
import { clearExhibitorRegistration } from '../../../Redux/ExhibitorRegistrationSlice';
import { clearModalStatus } from '../../../Redux/ModalSlice';

const ERegistration = () => {
  const [content, setContent] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const userInformation = useSelector((state) => state.userInformation.value);
  const [canAccessTable, setCanAccessTable] = useState(false);
  const dispatch = useDispatch();
  const needToFetch = useSelector((state) => state.stateRefresh.value);
  const blockNavigation = useSelector((state) => state.blockNavigation);

  const getExhibitors = useCallback(() => {
    axios
      .post(
        '/rotr/getExhibitors',
        {},
        {
          params: { accessCode: userInformation.accessCode },
        }
      )
      .then((res) => {
        dispatch(setExhibitors(res.data));
      })
      .catch((err) => {
        console.error('Failed to fetch exhibitors:', err);
      });
  }, [dispatch, userInformation.accessCode]);

  const getRegistrationContent = useCallback(() => {
    axios
      .get('/rotr/e-registration/content')
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error('Failed to fetch content:', err);
      });
    axios
      .get('/rotr/registration/userInfo', {
        params: { accessCode: userInformation.accessCode },
      })
      .then((res) => {
        setUserInfo({
          exists: true,
          firstName: res.data[0].first_name ?? '',
          lastName: res.data[0].last_name ?? '',
          companyName: res.data[0].company_name ?? '',
          email: res.data[0].email_address ?? '',
          phone: res.data[0].phone_number ?? '',
        });
        setCanAccessTable(
          res.data[0].first_name &&
            res.data[0].last_name &&
            res.data[0].company_name &&
            res.data[0].email_address &&
            res.data[0].phone_number
        );
      })
      .catch((err) => {
        console.error('Failed to fetch content:', err);
        setUserInfo({
          exists: false,
          firstName: '',
          lastName: '',
          companyName: '',
          email: '',
          phone: '',
        });
      });
  }, [userInformation.accessCode]);

  const getHeader = useCallback(() => {
    if (content.length > 0) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  useEffect(() => {
    getExhibitors();
  }, [getExhibitors]);

  useEffect(() => {
    getRegistrationContent();
  }, [getRegistrationContent]);

  useEffect(() => {
    getHeader();
  }, [getHeader]);

  useEffect(() => {
    if (needToFetch) {
      getExhibitors();
      dispatch(setRegistrationTable(false));
    }
  }, [dispatch, getExhibitors, needToFetch]);

  useEffect(() => {
    return () => {
      dispatch(clearExhibitorRegistration());
      dispatch(clearModalStatus());
    };
  }, [dispatch]);

  const handleSaveUserInfo = () => {
    if (userInfo.exists) {
      return new Promise((resolve, reject) => {
        axios
          .put('/rotr/registration/userInfo', null, {
            params: {
              accessCode: userInformation.accessCode,
              firstName: userInfo.firstName,
              lastName: userInfo.lastName,
              companyName: userInfo.companyName,
              emailAddress: userInfo.email,
              phoneNumber: userInfo.phone,
            },
          })
          .then((res) => {
            setCanAccessTable(true);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .post('/rotr/registration/userInfo', null, {
            params: {
              accessCode: userInformation.accessCode,
              firstName: userInfo.firstName,
              lastName: userInfo.lastName,
              companyName: userInfo.companyName,
              emailAddress: userInfo.email,
              phoneNumber: userInfo.phone,
            },
          })
          .then((res) => {
            setUserInfo({
              ...userInfo,
              exists: true,
            });
            setCanAccessTable(true);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  };

  return (
    <>
      <Grid container justifyContent='center' sx={{ mb: 3 }}>
        {userInformation.userType !== 'A1b2C3d4E5f6' ? (
          <>
            <Grid item container xs={12} md={12} id='E_REGISTRATION_ROW1_COL1' />
            {userInfo && (
              <UserInfo
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                handleSave={handleSaveUserInfo}
              />
            )}
            {!canAccessTable && (
              <Grid item xs={12} md={12}>
                <Typography
                  variant='h6'
                  component='div'
                  align='center'
                  sx={{
                    color: '#333',
                    my: 4,
                    padding: '20px',
                    fontStyle: 'italic',
                  }}
                >
                  All fields must be completed to continue with registration.
                </Typography>
              </Grid>
            )}
            {canAccessTable && (
              <Grid item xs={12} md={12}>
                <RegistrationContainer userInfo={userInfo} />
              </Grid>
            )}
            {canAccessTable && (
              <Grid container item xs={12} md={12} justifyContent='center'>
                <ERegistrationTable />
              </Grid>
            )}
            <Grid item container xs={12} md={12} id='E_REGISTRATION_ROW2_COL1' />
          </>
        ) : (
          <Grid item xs={12} md={12} mt={4}>
            <Typography
              variant='h4'
              component='div'
              align='center'
              sx={{
                color: '#333',
                mb: 4,
                padding: '20px',
                fontStyle: 'italic',
              }}
            >
              Employees are not allowed to register!
            </Typography>
            <EmbeddedVideo src={'https://www.youtube.com/embed/g_vZasFzMN4'} />
          </Grid>
        )}
      </Grid>
      <Prompt
        when={blockNavigation.when && blockNavigation.origin === 'e-registration'}
        message={blockNavigation.message}
        beforeUnload={true}
      />
    </>
  );
};

export default ERegistration;
