import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navigation/Navbar';
import Home from './Components/Home';
import Sponsors from './Components/Sponsors/Sponsors';
import Attendees from './Components/AttendeeDashboard/Attendees';
import Exhibitors from './Components/ExhibitorDashboard/Exhibitors';
// import ShowHighlights from './Components/ShowHighlights';
import Discover from './Components/Discover';
import Footer from './Components/Navigation/Footer';
import { Box } from '@mui/material';
import axios from 'axios';
import ARegistration from './Components/A-Registration/ARegistration';
import ERegistration from './Components/E-Registration/ERegistration';
import AccessModal from './Components/AccessModal';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInformation } from '../Redux/UserInformationSlice';
import Hotels from './Components/Travel/Hotels';
import Flights from './Components/Travel/Flights';
import ConfirmedExhibitors from './Components/AttendeeDashboard/ConfirmedExhibitors';
import ExhibitorResourceCenter from './Components/ExhibitorDashboard/ExhibitorResourceCenter';
import ExhibitorFAQ from './Components/ExhibitorDashboard/ExhibitorFAQ';
import AttendeeResourceCenter from './Components/AttendeeDashboard/AttendeeResourceCenter';
import AttendeeFAQ from './Components/AttendeeDashboard/AttendeeFAQ';

const RendezvousOnTheRed = () => {
  const [globalStyling, setGlobalStyling] = useState(null);
  const [cookie, setCookie] = useState(Cookies.get('access-code'));
  const userInformation = useSelector((state) => state.userInformation.value);
  const [accessCode, setAccessCode] = useState({
    value: cookie ?? userInformation.accessCode,
    hasBeenChecked: false,
    isValid: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Rendezvous on the Red';
    axios
      .get('/rotr/style/content')
      .then((res) => {
        setGlobalStyling(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (globalStyling && globalStyling.length > 0) {
      const styleElement = document.createElement('style');
      styleElement.innerHTML = globalStyling[0].content;

      document.head.appendChild(styleElement);

      return () => {
        document.head.removeChild(styleElement);
      };
    }
  }, [globalStyling]);

  useEffect(() => {
    if (accessCode.value != null && !accessCode.hasBeenChecked) {
      axios
        .post('/rotr/checkAccessCode', {
          accessCode: accessCode.value,
        })
        .then((res) => {
          setAccessCode({
            ...accessCode,
            hasBeenChecked: true,
            isValid: res.data.length > 0,
          });
          Cookies.set('access-code', accessCode.value, { expires: 400, path: '/' });
          dispatch(
            setUserInformation({
              accessCode: accessCode.value,
              userType: res.data[0].user_type,
            })
          );
        })
        .catch((err) => {
          console.error(err);
          setAccessCode({
            ...accessCode,
            hasBeenChecked: true,
            isValid: false,
          });
        });
    }
  }, [accessCode, dispatch]);

  const handleClose = () => {
    const cookie = Cookies.get('access-code');
    setCookie(cookie);
    setAccessCode({
      value: cookie ?? userInformation.accessCode,
      hasBeenChecked: true,
      isValid: true,
    });
  };

  if (accessCode.value != null && !accessCode.hasBeenChecked) {
    return null;
  }

  return accessCode.value == null || (accessCode.hasBeenChecked && !accessCode.isValid) ? (
    <>
      <AccessModal open={true} handleClose={handleClose} />
    </>
  ) : (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <Navbar />
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: '1600px',
            width: 'calc(100% - 32px)',
            m: '0 auto',
            px: '16px',
          }}
        >
          <Routes>
            <Route index element={<Home />} />
            {/* <Route path='/show-highlights' element={<ShowHighlights />} /> */}
            <Route path='/attendees' element={<Attendees />} />
            <Route path='/exhibitors' element={<Exhibitors />} />
            <Route path='/sponsors/*' element={<Sponsors />} />
            <Route path='/discover' element={<Discover />} />
            <Route path='/a-register' element={<ARegistration />} />
            <Route path='/e-register' element={<ERegistration />} />
            <Route path='/hotels' element={<Hotels />} />
            <Route path='/flights' element={<Flights />} />
            <Route path='/confirmed-exhibitors' element={<ConfirmedExhibitors />} />
            {/* <Route path='/exhibitor-resource-center' element={<ExhibitorResourceCenter />} /> */}
            {/* <Route path='/exhibitor-faq' element={<ExhibitorFAQ />} /> */}
            {/* <Route path='/attendee-resource-center' element={<AttendeeResourceCenter />} /> */}
            {/* <Route path='/attendee-faq' element={<AttendeeFAQ />} /> */}
          </Routes>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default RendezvousOnTheRed;
