import React from 'react';
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Add, Delete, Edit, ExpandMore, ExpandLess, Image, Save } from '@mui/icons-material';
import MaskedInput from 'react-text-mask';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '../../../Redux/ModalSlice';

const ARegistrationTableMobile = ({
  expandedBrandIds,
  expandedPersonnelIds,
  validateErrors,
  editingRows,
  handleExpandClick,
  formatPhoneNumber,
  handleBoothEdit,
  handleDeleteClick,
  handleAddPersonnel,
  handlePersonnelExpandClick,
  handlePersonnelInputChange,
  updateValidationError,
  handleBadgeModal,
  validateData,
  handleSave,
  handleEdit,
  deletePersonnel,
  states,
  parties,
  tourTimes,
  handlePersonnelStateChange,
  handlePersonnelPoiChange,
  handlePersonnelTourTimeChange,
  editingBoothRows,
  handleBoothSave,
  handleBoothInputChange,
  updateBoothValidationError,
  validateBoothData,
  validateBoothErrors,
}) => {
  const attendees = useSelector((state) => state.attendees.value);
  const highlightPersonnelAdd = useSelector((state) => state.modalStatus.attendeeBadges);
  const dispatch = useDispatch();

  return (
    <>
      <Grid item xs={12}>
        <Paper
          sx={{
            backgroundColor: '#f8f9fa',
            py: 2,
            '& .attendee-container:last-of-type': { mb: 0 },
          }}
        >
          {attendees.map((brand) => (
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: '4px',
                mb: 2,
              }}
              key={brand.id}
              className='attendee-container'
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  p: 2,
                  backgroundColor: alpha('#dbdad4', 0.5),
                  cursor: 'pointer',
                }}
                onMouseUp={() => {
                  if (!window.getSelection().toString()) {
                    handleExpandClick(brand.id);
                  }
                }}
              >
                <Typography sx={{ opacity: 0 }}>
                  <ExpandMore />
                </Typography>
                <Box display='flex' flexDirection='column' alignItems='center' textAlign='center'>
                  <Typography variant='h6' sx={{ fontWeight: 'bold', lineHeight: '1.5rem' }}>
                    {brand.company_name}
                  </Typography>
                </Box>
                <IconButton>
                  {expandedBrandIds.includes(brand.id) ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
              <Collapse in={expandedBrandIds.includes(brand.id)} timeout='auto' unmountOnExit>
                <Box sx={{ p: 2 }}>
                  {editingBoothRows.includes(brand.id) ? (
                    <>
                      <TextField
                        fullWidth
                        label='Company Name'
                        value={brand.company_name ?? ''}
                        onChange={(e) =>
                          handleBoothInputChange(brand.id, 'company_name', e.target.value)
                        }
                        margin='normal'
                        required
                        error={validateBoothErrors[brand.id]?.company_name}
                        onBlur={(e) =>
                          updateBoothValidationError(brand.id, 'company_name', !e.target.value)
                        }
                      />
                      <MaskedInput
                        mask={[
                          '(',
                          /[1-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        placeholder='(123) 456-7890'
                        value={brand.phone_number ?? ''}
                        onChange={(e) =>
                          handleBoothInputChange(brand.id, 'phone_number', e.target.value)
                        }
                        onBlur={(e) =>
                          updateBoothValidationError(brand.id, 'phone_number', !e.target.value)
                        }
                        render={(ref, props) => (
                          <TextField
                            fullWidth
                            label='Company Phone #'
                            inputRef={ref}
                            margin='normal'
                            required
                            error={validateBoothErrors[brand.id]?.phone_number}
                            {...props}
                          />
                        )}
                      />
                      <TextField
                        fullWidth
                        label='Email Address'
                        value={brand.email_address ?? ''}
                        onChange={(e) =>
                          handleBoothInputChange(brand.id, 'email_address', e.target.value)
                        }
                        margin='normal'
                        required
                        error={validateBoothErrors[brand.id]?.email_address}
                        onBlur={(e) =>
                          updateBoothValidationError(brand.id, 'email_address', !e.target.value)
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Typography variant='body1'>
                        <strong>Company Name:</strong>
                        <Box component='span' sx={{ float: 'right' }}>
                          {brand.company_name}
                        </Box>
                      </Typography>
                      <Typography variant='body1'>
                        <strong>Company Phone #:</strong>
                        <Box component='span' sx={{ float: 'right' }}>
                          {formatPhoneNumber(brand.phone_number)}
                        </Box>
                      </Typography>
                      <Typography variant='body1'>
                        <strong>Company Email:</strong>
                        <Box component='span' sx={{ float: 'right' }}>
                          {brand.email_address}
                        </Box>
                      </Typography>
                    </>
                  )}
                  <Stack direction='row' justifyContent='center' spacing={4} my={2}>
                    {editingBoothRows.includes(brand.id) ? (
                      <IconButton
                        onClick={() => {
                          if (validateBoothData(brand)) {
                            handleBoothSave(brand.id);
                          }
                        }}
                      >
                        <Stack alignItems='center'>
                          <Save />
                          <Typography variant='caption'>SAVE</Typography>
                        </Stack>
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => handleBoothEdit(brand.id)}
                        disabled={brand.allow_edit === 0}
                      >
                        <Stack alignItems='center'>
                          <Edit />
                          <Typography variant='caption'>EDIT</Typography>
                        </Stack>
                      </IconButton>
                    )}
                    <IconButton onClick={() => handleDeleteClick(brand.id)}>
                      <Stack alignItems='center'>
                        <Delete />
                        <Typography variant='caption'>DELETE</Typography>
                      </Stack>
                    </IconButton>
                  </Stack>
                  <Box display='flex' flexDirection='column' alignItems='center' my={2}>
                    <Button
                      onClick={() => {
                        dispatch(setModalStatus({ attendeeBadges: false }));
                        handleAddPersonnel(brand.id);
                      }}
                      startIcon={<Add />}
                      sx={{
                        '@keyframes blinkOutline': {
                          '0%': {
                            outline: '2px solid rgba(255, 0, 0, 0)',
                          },
                          '50%': {
                            outline: '2px solid rgba(255, 0, 0, 1)',
                          },
                          '100%': {
                            outline: '2px solid rgba(255, 0, 0, 0)',
                          },
                        },
                        animation: highlightPersonnelAdd ? 'blinkOutline 2s infinite' : 'none',
                      }}
                    >
                      Add Attendees (badges)
                    </Button>
                  </Box>
                  {brand.personnel.map((person) => (
                    <Box
                      key={person.badge_id}
                      sx={{ mt: 2, border: '1px solid #ccc', borderRadius: '4px' }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          p: 2,
                          backgroundColor: alpha('#dbdad4', 0.5),
                          cursor: 'pointer',
                        }}
                        onMouseUp={() => {
                          if (!window.getSelection().toString()) {
                            handlePersonnelExpandClick(brand.id, person.badge_id);
                          }
                        }}
                      >
                        <Typography sx={{ opacity: 0 }}>
                          <ExpandMore />
                        </Typography>
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                          {person.first_name} {person.last_name}
                        </Typography>
                        <IconButton>
                          {expandedPersonnelIds[brand.id] &&
                          expandedPersonnelIds[brand.id].includes(person.badge_id) ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </IconButton>
                      </Box>
                      <Collapse
                        in={
                          expandedPersonnelIds[brand.id] &&
                          expandedPersonnelIds[brand.id].includes(person.badge_id)
                        }
                        timeout='auto'
                        unmountOnExit
                      >
                        <Box sx={{ p: 2 }}>
                          {editingRows.includes(person.badge_id) ? (
                            <>
                              <TextField
                                label='First Name'
                                fullWidth
                                value={person.first_name ?? ''}
                                onChange={(e) =>
                                  handlePersonnelInputChange(
                                    brand.id,
                                    person.badge_id,
                                    'first_name',
                                    e.target.value
                                  )
                                }
                                margin='normal'
                                required
                                error={validateErrors[person.badge_id]?.first_name}
                                onBlur={(e) =>
                                  updateValidationError(
                                    person.badge_id,
                                    'first_name',
                                    !e.target.value
                                  )
                                }
                              />
                              <TextField
                                label='Last Name'
                                fullWidth
                                value={person.last_name ?? ''}
                                onChange={(e) =>
                                  handlePersonnelInputChange(
                                    brand.id,
                                    person.badge_id,
                                    'last_name',
                                    e.target.value
                                  )
                                }
                                margin='normal'
                                required
                                error={validateErrors[person.badge_id]?.last_name}
                                onBlur={(e) =>
                                  updateValidationError(
                                    person.badge_id,
                                    'last_name',
                                    !e.target.value
                                  )
                                }
                              />
                              <TextField
                                label='Job Title'
                                fullWidth
                                value={person.job_title ?? ''}
                                onChange={(e) =>
                                  handlePersonnelInputChange(
                                    brand.id,
                                    person.badge_id,
                                    'job_title',
                                    e.target.value
                                  )
                                }
                                margin='normal'
                              />
                              <TextField
                                label='Company Name'
                                fullWidth
                                value={person.company_name ?? ''}
                                onChange={(e) =>
                                  handlePersonnelInputChange(
                                    brand.id,
                                    person.badge_id,
                                    'company_name',
                                    e.target.value
                                  )
                                }
                                margin='normal'
                                required
                                error={validateErrors[person.badge_id]?.company_name}
                                onBlur={(e) =>
                                  updateValidationError(
                                    person.badge_id,
                                    'company_name',
                                    !e.target.value
                                  )
                                }
                              />
                              <TextField
                                label='City'
                                fullWidth
                                value={person.city ?? ''}
                                onChange={(e) =>
                                  handlePersonnelInputChange(
                                    brand.id,
                                    person.badge_id,
                                    'city',
                                    e.target.value
                                  )
                                }
                                margin='normal'
                                required
                                error={validateErrors[person.badge_id]?.city}
                                onBlur={(e) =>
                                  updateValidationError(person.badge_id, 'city', !e.target.value)
                                }
                              />
                              <Autocomplete
                                options={states}
                                getOptionLabel={(option) =>
                                  `${option.description} (${option.abbreviation})`
                                }
                                value={
                                  states.find((state) => state.state_id === person.state_id) ?? null
                                }
                                onChange={(e, value) => {
                                  handlePersonnelStateChange(
                                    brand.id,
                                    person.badge_id,
                                    value?.state_id ?? null,
                                    value?.abbreviation ?? null
                                  );
                                }}
                                onBlur={(e) =>
                                  updateValidationError(
                                    person.badge_id,
                                    'state_id',
                                    !e.target.value
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label='State'
                                    fullWidth
                                    margin='normal'
                                    required
                                    error={validateErrors[person.badge_id]?.state_id}
                                  />
                                )}
                              />
                              <TextField
                                label='Email Address'
                                fullWidth
                                value={person.email_address ?? ''}
                                onChange={(e) =>
                                  handlePersonnelInputChange(
                                    brand.id,
                                    person.badge_id,
                                    'email_address',
                                    e.target.value
                                  )
                                }
                                margin='normal'
                                required
                                error={validateErrors[person.badge_id]?.email_address}
                                onBlur={(e) =>
                                  updateValidationError(
                                    person.badge_id,
                                    'email_address',
                                    !e.target.value
                                  )
                                }
                              />
                              <MaskedInput
                                mask={[
                                  '(',
                                  /[1-9]/,
                                  /\d/,
                                  /\d/,
                                  ')',
                                  ' ',
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  '-',
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                placeholder='(123) 456-7890'
                                value={person.phone_number ?? ''}
                                onChange={(e) =>
                                  handlePersonnelInputChange(
                                    brand.id,
                                    person.badge_id,
                                    'phone_number',
                                    e.target.value
                                  )
                                }
                                render={(ref, props) => (
                                  <TextField
                                    fullWidth
                                    label='Cell Phone #'
                                    margin='normal'
                                    inputRef={ref}
                                    {...props}
                                  />
                                )}
                              />
                              <Box
                                sx={{
                                  pt: 2,
                                  textAlign: 'center',
                                  width: '80%',
                                  m: '0 auto',
                                }}
                              >
                                <Typography variant='body1'>
                                  On Monday night, select manufacturers will be hosting private
                                  events throughout the downtown area. At which party would you like
                                  to start your evening?
                                </Typography>
                              </Box>
                              <Autocomplete
                                options={parties}
                                getOptionLabel={(option) => option.description}
                                value={
                                  parties.find((party) => party.poi_id === person.poi_id) ?? null
                                }
                                onChange={(e, value) => {
                                  handlePersonnelPoiChange(
                                    brand.id,
                                    person.badge_id,
                                    value?.poi_id ?? null,
                                    value?.description ?? null
                                  );
                                }}
                                onBlur={(e) =>
                                  updateValidationError(person.badge_id, 'poi_id', !e.target.value)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label='Manufacturer Party'
                                    margin='normal'
                                    fullWidth
                                    required
                                    error={validateErrors[person.badge_id]?.poi_id}
                                  />
                                )}
                              />
                              <Box
                                sx={{
                                  pt: 2,
                                  textAlign: 'center',
                                  width: '80%',
                                  m: '0 auto',
                                }}
                              >
                                <Typography variant='body1'>
                                  We invite you to experience a guided tour of our state-of-the-art
                                  warehouse during your visit. Please select your preferred tour
                                  time:
                                </Typography>
                              </Box>
                              <Autocomplete
                                options={tourTimes}
                                getOptionLabel={(option) => option.description}
                                value={
                                  tourTimes.find((tt) => tt.tour_time_id === person.tour_time_id) ??
                                  null
                                }
                                onChange={(e, value) => {
                                  handlePersonnelTourTimeChange(
                                    brand.id,
                                    person.badge_id,
                                    value?.tour_time_id ?? null,
                                    value?.description ?? null
                                  );
                                }}
                                onBlur={(e) =>
                                  updateValidationError(
                                    person.badge_id,
                                    'tour_time_id',
                                    !e.target.value
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label='Tour Time'
                                    margin='normal'
                                    fullWidth
                                    required
                                    error={validateErrors[person.badge_id]?.tour_time_id}
                                  />
                                )}
                              />
                            </>
                          ) : (
                            <>
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                              >
                                <Box display='flex' alignItems='center'>
                                  <Box
                                    sx={{
                                      width: 20,
                                      height: 20,
                                      backgroundColor: '#00325A',
                                      marginRight: 1,
                                    }}
                                  />
                                  <Typography variant='body1' fontWeight={600}>
                                    First Name:
                                  </Typography>
                                </Box>
                                <Typography variant='body1'>{person.first_name}</Typography>
                              </Box>
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                              >
                                <Box display='flex' alignItems='center'>
                                  <Box
                                    sx={{
                                      width: 20,
                                      height: 20,
                                      backgroundColor: '#00325A',
                                      marginRight: 1,
                                    }}
                                  />
                                  <Typography variant='body1' fontWeight={600}>
                                    Last Name:
                                  </Typography>
                                </Box>
                                <Typography variant='body1'>{person.last_name}</Typography>
                              </Box>
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                              >
                                <Box display='flex' alignItems='center'>
                                  <Box
                                    sx={{
                                      width: 20,
                                      height: 20,
                                      backgroundColor: '#00325A',
                                      marginRight: 1,
                                    }}
                                  />
                                  <Typography variant='body1' fontWeight={600}>
                                    Job Title:
                                  </Typography>
                                </Box>
                                <Typography variant='body1'>{person.job_title}</Typography>
                              </Box>
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                              >
                                <Box display='flex' alignItems='center'>
                                  <Box
                                    sx={{
                                      width: 20,
                                      height: 20,
                                      backgroundColor: '#00325A',
                                      marginRight: 1,
                                    }}
                                  />
                                  <Typography variant='body1' fontWeight={600}>
                                    Company Name:
                                  </Typography>
                                </Box>
                                <Typography variant='body1'>{person.company_name}</Typography>
                              </Box>
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                              >
                                <Box display='flex' alignItems='center'>
                                  <Box
                                    sx={{
                                      width: 20,
                                      height: 20,
                                      backgroundColor: '#00325A',
                                      marginRight: 1,
                                    }}
                                  />
                                  <Typography variant='body1' fontWeight={600}>
                                    City:
                                  </Typography>
                                </Box>
                                <Typography variant='body1'>{person.city}</Typography>
                              </Box>
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                              >
                                <Box display='flex' alignItems='center'>
                                  <Box
                                    sx={{
                                      width: 20,
                                      height: 20,
                                      backgroundColor: '#00325A',
                                      marginRight: 1,
                                    }}
                                  />
                                  <Typography variant='body1' fontWeight={600}>
                                    State:
                                  </Typography>
                                </Box>
                                <Typography variant='body1'>{person.state}</Typography>
                              </Box>
                              <Typography variant='body1'>
                                <strong>Email Address:</strong>
                                <Box component='span' sx={{ float: 'right' }}>
                                  {person.email_address}
                                </Box>
                              </Typography>
                              <Typography variant='body1'>
                                <strong>Cell Phone #:</strong>
                                <Box component='span' sx={{ float: 'right' }}>
                                  {formatPhoneNumber(person.phone_number)}
                                </Box>
                              </Typography>
                              <Box
                                sx={{
                                  pt: 2,
                                  textAlign: 'center',
                                  width: '90%',
                                  m: '0 auto',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                <Typography variant='body1'>
                                  On Monday night, select manufacturers will be hosting private
                                  events throughout the downtown area. You've chosen to start your
                                  evening at the party of:{'  '}
                                  <strong style={{ whiteSpace: 'pre' }}>
                                    {person.poi ?? 'None selected'}
                                  </strong>
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  pt: 2,
                                  textAlign: 'center',
                                  width: '90%',
                                  m: '0 auto',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                <Typography variant='body1'>
                                  We invite you to experience a guided tour of our state-of-the-art
                                  warehouse during your visit. You have selected your preferred tour
                                  time to be:{'  '}
                                  <strong style={{ whiteSpace: 'pre' }}>
                                    {person.tour_time ?? 'None selected'}
                                  </strong>
                                </Typography>
                              </Box>
                            </>
                          )}
                          <Stack direction='row' justifyContent='center' spacing={4} mt={2}>
                            <IconButton onClick={() => handleBadgeModal(person)}>
                              <Stack alignItems='center'>
                                <Image />
                                <Typography variant='caption'>PREVIEW</Typography>
                              </Stack>
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                if (editingRows.includes(person.badge_id)) {
                                  if (validateData(person)) {
                                    handleSave(brand.id, person.badge_id);
                                  }
                                } else {
                                  handleEdit(person.badge_id);
                                }
                              }}
                            >
                              <Stack alignItems='center'>
                                {editingRows.includes(person.badge_id) ? <Save /> : <Edit />}
                                <Typography variant='caption'>
                                  {editingRows.includes(person.badge_id) ? 'SAVE' : 'EDIT'}
                                </Typography>
                              </Stack>
                            </IconButton>
                            <IconButton onClick={() => deletePersonnel(brand.id, person.badge_id)}>
                              <Stack alignItems='center'>
                                <Delete />
                                <Typography variant='caption'>DELETE</Typography>
                              </Stack>
                            </IconButton>
                          </Stack>
                        </Box>
                      </Collapse>
                    </Box>
                  ))}
                  {brand.personnel.length > 0 && (
                    <Box mt={2} textAlign='center'>
                      <Typography mb={1}>
                        Required columns are marked with (*) when editing.
                      </Typography>
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        whiteSpace='pre'
                      >
                        <Typography>Info marked with</Typography>
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            backgroundColor: '#00325A',
                            mx: 1,
                          }}
                        />
                        <Typography>will be printed on badge.</Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Collapse>
            </Box>
          ))}
        </Paper>
      </Grid>
    </>
  );
};

export default ARegistrationTableMobile;
