import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button, alpha } from '@mui/material';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { setAttendeeRegistration } from '../../../Redux/AttendeeRegistrationSlice';
import axios from 'axios';
import { setRegistrationTable } from '../../../Redux/StateRefreshSlice';

const RegistrationForm = () => {
  const [touchedFields, setTouchedFields] = useState({
    email: false,
    phone: false,
  });
  const dispatch = useDispatch();
  const attendeeRegistration = useSelector((state) => state.attendeeRegistration);
  const accessCode = useSelector((state) => state.userInformation.value.accessCode);

  useEffect(() => {
    console.log(accessCode);
    axios
      .post(
        '/rotr/attendees/prefill',
        {},
        {
          params: { accessCode: accessCode },
        }
      )
      .then((res) => {
        dispatch(
          setAttendeeRegistration({
            attendeeName: res.data[0].company_name,
            attendeePhone: res.data[0].phone_number,
            attendeeEmail: res.data[0].email_address,
            accountNumber: res.data[0].account_number,
          })
        );
      });
  }, [dispatch, accessCode]);

  const handleNameChange = (e) => {
    dispatch(setAttendeeRegistration({ attendeeName: e.target.value }));
  };

  const handlePhoneNumberChange = (e) => {
    const rawPhoneNumber = e.target.value.replace(/\D/g, '');
    dispatch(setAttendeeRegistration({ attendeePhone: rawPhoneNumber }));
    !validator.isMobilePhone(rawPhoneNumber)
      ? dispatch(setAttendeeRegistration({ attendeePhoneError: true }))
      : dispatch(setAttendeeRegistration({ attendeePhoneError: false }));
  };

  const handleEmailChange = (e) => {
    dispatch(setAttendeeRegistration({ attendeeEmail: e.target.value }));
    !validator.isEmail(e.target.value)
      ? dispatch(setAttendeeRegistration({ attendeeEmailError: true }))
      : dispatch(setAttendeeRegistration({ attendeeEmailError: false }));
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return phoneNumber;
    const phoneNumberDigits = phoneNumber.replace(/\D/g, '');
    const phoneNumberLength = phoneNumberDigits.length;
    if (phoneNumberLength < 4) return phoneNumberDigits;
    if (phoneNumberLength < 7) {
      return `(${phoneNumberDigits.slice(0, 3)}) ${phoneNumberDigits.slice(3)}`;
    }
    return `(${phoneNumberDigits.slice(0, 3)}) ${phoneNumberDigits.slice(
      3,
      6
    )}-${phoneNumberDigits.slice(6, 10)}`;
  };

  const handleBlur = (field) => {
    setTouchedFields({ ...touchedFields, [field]: true });
  };

  const submitRegistration = () => {
    axios
      .post('/rotr/attendees/register', {
        accessCode: accessCode,
        attendeeName: attendeeRegistration.attendeeName,
        attendeePhone: attendeeRegistration.attendeePhone,
        attendeeEmail: attendeeRegistration.attendeeEmail,
      })
      .then((res) => {
        console.log(res);
      });
    dispatch(setAttendeeRegistration({ isRegistered: true }));
    dispatch(setRegistrationTable(true));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label='Account Number'
          value={attendeeRegistration.accountNumber}
          disabled
          InputLabelProps={{ shrink: true }}
          helperText='If you need to change your account number, please contact your sales representative.'
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label='Company Name'
          value={attendeeRegistration.attendeeName}
          onChange={handleNameChange}
          onBlur={() => handleBlur('companyName')}
          error={touchedFields.companyName && attendeeRegistration.attendeeName === ''}
          helperText={
            touchedFields.companyName && attendeeRegistration.attendeeName === ''
              ? 'Company name is required.'
              : ' '
          }
          required
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label='Phone Number'
          value={formatPhoneNumber(attendeeRegistration.attendeePhone)}
          onChange={handlePhoneNumberChange}
          onBlur={() => handleBlur('phone')}
          error={touchedFields.phone && attendeeRegistration.attendeePhoneError}
          helperText={
            touchedFields.phone && attendeeRegistration.attendeePhoneError
              ? 'Please enter a valid phone number.'
              : ' '
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label='Email'
          value={attendeeRegistration.attendeeEmail}
          onChange={handleEmailChange}
          onBlur={() => handleBlur('email')}
          error={touchedFields.email && attendeeRegistration.attendeeEmailError}
          helperText={
            touchedFields.email && attendeeRegistration.attendeeEmailError
              ? 'Please enter a valid email address.'
              : ' '
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant='contained'
          onClick={submitRegistration}
          sx={{
            backgroundColor: '#00325a',
            '&:hover': { backgroundColor: alpha('#00325a', 0.6) },
          }}
        >
          Confirm
        </Button>
      </Grid>
    </Grid>
  );
};

export default RegistrationForm;
