import { createSlice } from '@reduxjs/toolkit';

export const AttendeeRegistrationSlice = createSlice({
  name: 'attendeeRegistration',
  initialState: {
    attendeeId: '',
    attendeeName: '',
    attendeeEmail: '',
    attendeeEmailError: false,
    attendeePhone: '',
    attendeePhoneError: false,
    attendeeAccountNumber: '',
    isRegistered: false,
  },
  reducers: {
    setAttendeeRegistration: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setAttendeeRegistration } = AttendeeRegistrationSlice.actions;
export default AttendeeRegistrationSlice.reducer;
