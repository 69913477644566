import { alpha } from '@mui/material/styles';
import {
  Modal,
  Paper,
  Typography,
  List,
  ListItem,
  Box,
  Divider,
  Button,
  Grid,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';

const ViewRegistrationModal = ({ isModalOpen, handleClose, exhibitorId }) => {
  const [registrationData, setRegistrationData] = useState(null);

  useEffect(() => {
    if (exhibitorId) {
      axios
        .post(
          `/rotr/exhibitors/registrationData`,
          {},
          {
            params: {
              exhibitorId: exhibitorId,
            },
          }
        )
        .then((response) => {
          setRegistrationData(response.data[0]);
        })
        .catch((error) => {
          console.error('Error fetching registration data:', error);
        });
    }
  }, [exhibitorId]);

  if (!registrationData) {
    return null;
  }

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return phoneNumber;
    const phoneNumberDigits = phoneNumber.replace(/\D/g, '');
    const phoneNumberLength = phoneNumberDigits.length;
    if (phoneNumberLength < 4) return phoneNumberDigits;
    if (phoneNumberLength < 7) {
      return `(${phoneNumberDigits.slice(0, 3)}) ${phoneNumberDigits.slice(3)}`;
    }
    return `(${phoneNumberDigits.slice(0, 3)}) ${phoneNumberDigits.slice(
      3,
      6
    )}-${phoneNumberDigits.slice(6, 10)}`;
  };

  const formatPrice = (price) => {
    return price.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    if (registrationData.booth_price) {
      totalPrice += Number(registrationData.booth_price);
    }
    if (registrationData.addons && registrationData.addons.length > 0) {
      registrationData.addons.forEach((addon) => {
        totalPrice += addon.addon_price * addon.addon_qty_selected;
      });
    }
    return totalPrice;
  };

  const totalPrice = calculateTotalPrice();

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        animation: 'fadeIn 0.3s ease',
        '@keyframes fadeIn': {
          from: { opacity: 0 },
          to: { opacity: 1 },
        },
      }}
    >
      <Paper
        sx={{
          p: 4,
          maxWidth: 600,
          width: '100%',
          maxHeight: '80vh',
          overflowY: 'auto',
          borderRadius: 3,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#f7f8fa',
        }}
      >
        <Typography
          variant='h5'
          sx={{
            fontWeight: 'bold',
            marginBottom: 2,
            textAlign: 'center',
            color: '#00325a',
            fontSize: '1.75rem',
          }}
        >
          View Registration
        </Typography>
        <Divider sx={{ marginBottom: 3 }} />

        <Box
          sx={{
            py: 1.5,
            px: 2,
            backgroundColor: '#fff',
            borderRadius: 2,
            mb: 3,
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant='h6' sx={{ fontWeight: 'bold', color: '#00325a', mb: 1 }}>
            Exhibitor Information
          </Typography>
          <List disablePadding>
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                py: 1.5,
                px: 2,
                backgroundColor: '#fff',
                borderRadius: 2,
                mb: 1,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Box>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                >
                  Company Name
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {registrationData.exhibitor_name}
                </Typography>
              </Box>
            </ListItem>
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                py: 1.5,
                px: 2,
                backgroundColor: '#fff',
                borderRadius: 2,
                mb: 1,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Box>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                >
                  Phone Number
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {formatPhoneNumber(registrationData.exhibitor_phone)}
                </Typography>
              </Box>
            </ListItem>
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                py: 1.5,
                px: 2,
                backgroundColor: '#fff',
                borderRadius: 2,
                mb: 1,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Box>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                >
                  Email Address
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {registrationData.exhibitor_email}
                </Typography>
              </Box>
            </ListItem>
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                py: 1.5,
                px: 2,
                backgroundColor: '#fff',
                borderRadius: 2,
                mb: 1,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Box>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                >
                  Company Website
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {registrationData.exhibitor_website}
                </Typography>
              </Box>
            </ListItem>
          </List>
        </Box>

        {registrationData.booth_number && (
          <Box
            sx={{
              py: 1.5,
              px: 2,
              backgroundColor: '#fff',
              borderRadius: 2,
              mb: 3,
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold', color: '#00325a', marginBottom: 1 }}>
              Booth Selection
            </Typography>
            <List disablePadding>
              <ListItem
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  py: 1.5,
                  px: 2,
                  backgroundColor: '#fff',
                  borderRadius: 2,
                  mb: 1,
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Box>
                  <Typography
                    variant='body1'
                    sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                  >
                    Booth: {registrationData.booth_number}
                  </Typography>
                  <Typography variant='body2' color='textSecondary'>
                    Type: {registrationData.booth_type}
                  </Typography>
                </Box>
                <Typography
                  variant='body2'
                  sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                >
                  {`$${formatPrice(Number(registrationData.booth_price))}`}
                </Typography>
              </ListItem>
            </List>
          </Box>
        )}

        {registrationData.addons && registrationData.addons.length > 0 && (
          <Box
            sx={{
              py: 1.5,
              px: 2,
              backgroundColor: '#fff',
              borderRadius: 2,
              mb: 3,
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold', marginBottom: 1, color: '#00325a' }}>
              Add-ons
            </Typography>
            <List disablePadding>
              {registrationData.addons.map((addon, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    py: 1.5,
                    px: 2,
                    backgroundColor: '#fff',
                    borderRadius: 2,
                    mb: 1,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Box>
                    <Typography
                      variant='body1'
                      sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                    >
                      {addon.addon_description}
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                      {`Price: $${formatPrice(addon.addon_price)}`}
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                      {`Quantity: ${addon.addon_qty_selected}`}
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                  >
                    {`$${formatPrice(addon.addon_price * addon.addon_qty_selected)}`}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        <Box
          sx={{
            py: 1.5,
            px: 2,
            backgroundColor: '#fff',
            borderRadius: 2,
            mb: 3,
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            variant='body1'
            sx={{
              fontWeight: 'bold',
              color: '#00325a',
              fontSize: '1.1rem',
            }}
          >
            Total Price: {`$${formatPrice(totalPrice)}`}
          </Typography>
        </Box>

        <Box
          sx={{
            py: 1.5,
            px: 2,
            backgroundColor: '#fff',
            borderRadius: 2,
            mb: 3,
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant='h6' sx={{ fontWeight: 'bold', color: '#00325a', marginBottom: 1 }}>
            Payment Details
          </Typography>
          <List disablePadding>
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                py: 1.5,
                px: 2,
                backgroundColor: '#fff',
                borderRadius: 2,
                mb: 1,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Box>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                >
                  Payment Method
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {registrationData.payment_type}
                </Typography>
              </Box>
            </ListItem>
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                py: 1.5,
                px: 2,
                backgroundColor: '#fff',
                borderRadius: 2,
                mb: 1,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Box>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                >
                  Billing Email
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {registrationData.payment_email}
                </Typography>
              </Box>
            </ListItem>
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                py: 1.5,
                px: 2,
                backgroundColor: '#fff',
                borderRadius: 2,
                mb: 1,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Box>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                >
                  Notes
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {registrationData.payment_notes}
                </Typography>
              </Box>
            </ListItem>
          </List>
        </Box>

        <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant='contained'
              fullWidth
              onClick={handleClose}
              startIcon={<CancelIcon />}
              sx={{
                backgroundColor: '#cb351a',
                '&:hover': {
                  backgroundColor: alpha('#cb351a', 0.8),
                },
                borderRadius: 2,
                fontWeight: 'bold',
                color: '#fff',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                textTransform: 'none',
                fontSize: '1rem',
                padding: '8px 16px',
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default ViewRegistrationModal;
