import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { Autocomplete, ButtonBase, Grid, lighten, TextField, Tooltip } from '@mui/material';
import { Card, styled, CardMedia, CardContent, Typography } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const StyledCard = styled(Card, { shouldForwardProp: (prop) => prop !== 'isSponsor' })(
  ({ theme, isSponsor }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.spacing(2),
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    width: '100%',
    height: '100%',
    outline: isSponsor ? `4px solid #cb351a` : 'none',
    position: 'relative',
  })
);

const StyledCardMedia = styled(CardMedia)({
  height: 120,
  backgroundSize: 'contain',
  margin: '16px',
});

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.spacing(1),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  lineHeight: 1.5,
  justifyContent: 'center',
  textAlign: 'center',
  flexGrow: 1,
}));

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: '#cb351a',
  ':hover': {
    color: lighten('#cb351a', 0.3),
  },
}));

const ConfirmedExhibitors = () => {
  const [content, setContent] = useState(null);
  const [exhibitors, setExhibitors] = useState([]);
  const [exhibitorFilter, setExhibitorFilter] = useState('');
  const debounceTimeoutRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get('/rotr/confirmedExhibitors/content')
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
    axios
      .get('/rotr/attendees/exhibitorBoothsAndLogos')
      .then((res) => {
        setExhibitors(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  const filteredExhibitors = useMemo(() => {
    return exhibitors.filter(
      (exhibitor) => !exhibitorFilter || exhibitor.exhibitor.toLowerCase().includes(exhibitorFilter)
    );
  }, [exhibitors, exhibitorFilter]);

  const handleExhibitorFilterChange = (_, newValue) => {
    const newFilter = (newValue ?? '').trim().toLowerCase();

    // Clear any existing timeout
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Set a new timeout to update the filter after 250ms, or 50ms if empty for snappier feel
    debounceTimeoutRef.current = setTimeout(
      () => {
        setExhibitorFilter(newFilter);
      },
      newFilter === '' ? 50 : 250
    );
  };

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item container xs={12} md={12} id='CONFIRMED_EXHIBITORS_ROW1_COL1' />
      {exhibitors.length > 0 && (
        <>
          <Grid
            item
            container
            xs={12}
            sx={{ justifyContent: 'center', alignItems: 'center', mb: 2, gap: 2 }}
          >
            <Autocomplete
              freeSolo
              options={[]}
              onInputChange={handleExhibitorFilterChange}
              renderInput={(params) => <TextField {...params} label='Filter...' />}
              sx={{ maxWidth: '400px', width: '100%' }}
            />
            <Typography variant='h6'>
              Showing <b>{filteredExhibitors.length}</b> Exhibitors
            </Typography>
          </Grid>
          {filteredExhibitors.map((exhibitor) => (
            <Grid item xs={6} sm={4} md={3} lg={2} key={exhibitor.exhibitor}>
              <StyledCard isSponsor={exhibitor.category === 'sponsor'}>
                {exhibitor.category === 'sponsor' && exhibitor.sponsor_link && (
                  <Tooltip title='View sponsor information' placement='top'>
                    <StyledButtonBase
                      onClick={() => navigate(`/sponsors/${exhibitor.sponsor_link}`)}
                    >
                      <OpenInNew />
                    </StyledButtonBase>
                  </Tooltip>
                )}
                <StyledCardMedia image={exhibitor.logo} title={exhibitor.exhibitor} />
                <StyledCardContent>
                  <StyledTypography variant='h6'>{exhibitor.exhibitor.trim()}</StyledTypography>
                  <Typography variant='body1' align='center'>
                    Booth: <b>{exhibitor.booth_number}</b>
                  </Typography>
                </StyledCardContent>
              </StyledCard>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

export default ConfirmedExhibitors;
