import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Add, Delete, Edit, ExpandMore, Image, Save } from '@mui/icons-material';
import MaskedInput from 'react-text-mask';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '../../../Redux/ModalSlice';

const ARegistrationTableDesktop = ({
  validateErrors,
  editingRows,
  handleBoothEdit,
  handleDeleteClick,
  formatPhoneNumber,
  handleAddPersonnel,
  handleBadgeModal,
  handleEdit,
  deletePersonnel,
  handlePersonnelInputChange,
  updateValidationError,
  validateData,
  handleSave,
  accordionExpanded,
  setAccordionExpanded,
  states,
  parties,
  tourTimes,
  handlePersonnelStateChange,
  handlePersonnelPoiChange,
  handlePersonnelTourTimeChange,
  editingBoothRows,
  handleBoothSave,
  handleBoothInputChange,
  updateBoothValidationError,
  validateBoothData,
  validateBoothErrors,
}) => {
  const attendees = useSelector((state) => state.attendees.value);
  const highlightPersonnelAdd = useSelector((state) => state.modalStatus.attendeeBadges);
  const dispatch = useDispatch();

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        width: '100%',
        backgroundColor: '#f8f9fa',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      {attendees.map((brand, index) => (
        <Table
          size='small'
          sx={{
            mb: index === attendees.length - 1 ? 0 : 2,
            outline: `solid 2px ${alpha('#00325a', 0.5)}`,
            backgroundColor: 'white',
          }}
          key={brand.id}
        >
          <TableHead>
            <TableRow sx={{ outline: '1px solid #ccc' }}>
              <TableCell />
              <TableCell
                sx={{
                  textAlign: 'center',
                  borderLeft: 'solid 1px lightgrey',
                }}
              >
                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                  {editingBoothRows.includes(brand.id) ? 'Company Name *' : 'Company Name'}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  borderLeft: 'solid 1px lightgrey',
                }}
              >
                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                  {editingBoothRows.includes(brand.id) ? 'Company Phone # *' : 'Company Phone #'}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  borderLeft: 'solid 1px lightgrey',
                }}
              >
                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                  {editingBoothRows.includes(brand.id) ? 'Company Email *' : 'Company Email'}
                </Typography>
              </TableCell>
              {editingBoothRows.includes(brand.id) && (
                <TableCell
                  sx={{
                    textAlign: 'center',
                    borderLeft: 'solid 1px lightgrey',
                  }}
                />
              )}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              '& tr:nth-last-of-type(1) td': {
                borderBottom: 'none',
              },
              '& td:not(:first-of-type)': {
                wordBreak: 'break-word',
              },
            }}
          >
            <TableRow sx={{ outline: '1px solid #ccc' }}>
              <TableCell sx={{ padding: '16px' }}>
                <Box display='flex' justifyContent='center' alignItems='center' columnGap={1}>
                  {!editingBoothRows.includes(brand.id) && (
                    <IconButton
                      onClick={() => handleBoothEdit(brand.id)}
                      disabled={brand.allow_edit === 0}
                    >
                      <Stack alignItems='center'>
                        <Edit />
                        <Typography variant='caption'>EDIT</Typography>
                      </Stack>
                    </IconButton>
                  )}
                  <IconButton onClick={() => handleDeleteClick(brand.id)}>
                    <Stack alignItems='center'>
                      <Delete />
                      <Typography variant='caption'>DELETE</Typography>
                    </Stack>
                  </IconButton>
                </Box>
              </TableCell>
              <TableCell>
                {editingBoothRows.includes(brand.id) ? (
                  <TextField
                    fullWidth
                    placeholder='Company Name'
                    value={brand.company_name ?? ''}
                    onChange={(e) =>
                      handleBoothInputChange(brand.id, 'company_name', e.target.value)
                    }
                    required
                    error={validateBoothErrors[brand.id]?.company_name}
                    onBlur={(e) =>
                      updateBoothValidationError(brand.id, 'company_name', !e.target.value)
                    }
                  />
                ) : (
                  <Typography variant='body1' textAlign='center'>
                    {brand.company_name}
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                {editingBoothRows.includes(brand.id) ? (
                  <MaskedInput
                    mask={[
                      '(',
                      /[1-9]/,
                      /\d/,
                      /\d/,
                      ')',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    placeholder='(123) 456-7890'
                    value={brand.phone_number ?? ''}
                    onChange={(e) =>
                      handleBoothInputChange(brand.id, 'phone_number', e.target.value)
                    }
                    onBlur={(e) =>
                      updateBoothValidationError(brand.id, 'phone_number', !e.target.value)
                    }
                    render={(ref, props) => (
                      <TextField
                        fullWidth
                        placeholder='Company Phone #'
                        inputRef={ref}
                        required
                        error={validateBoothErrors[brand.id]?.phone_number}
                        {...props}
                      />
                    )}
                  />
                ) : (
                  <Typography variant='body1' textAlign='center'>
                    {formatPhoneNumber(brand.phone_number)}
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                {editingBoothRows.includes(brand.id) ? (
                  <TextField
                    fullWidth
                    placeholder='Email Address'
                    value={brand.email_address ?? ''}
                    onChange={(e) =>
                      handleBoothInputChange(brand.id, 'email_address', e.target.value)
                    }
                    required
                    error={validateBoothErrors[brand.id]?.email_address}
                    onBlur={(e) =>
                      updateBoothValidationError(brand.id, 'email_address', !e.target.value)
                    }
                  />
                ) : (
                  <Typography variant='body1' textAlign='center'>
                    {brand.email_address}
                  </Typography>
                )}
              </TableCell>
              {editingBoothRows.includes(brand.id) && (
                <TableCell sx={{ padding: '16px' }}>
                  <Box display='flex' justifyContent='center' alignItems='center' columnGap={1}>
                    <IconButton
                      onClick={() => {
                        if (validateBoothData(brand)) {
                          handleBoothSave(brand.id);
                        }
                      }}
                    >
                      <Stack alignItems='center'>
                        <Save />
                        <Typography variant='caption'>SAVE</Typography>
                      </Stack>
                    </IconButton>
                  </Box>
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell colSpan={5} sx={{ borderBottom: 'none' }}>
                <Box my={2}>
                  <Accordion
                    expanded={accordionExpanded.includes(brand.id)}
                    onClick={() => dispatch(setModalStatus({ attendeeBadges: false }))}
                    onChange={(e, isExpanded) =>
                      setAccordionExpanded((prev) => {
                        if (isExpanded) {
                          return [...prev, brand.id];
                        } else {
                          const i = prev.indexOf(brand.id);
                          return [...prev.slice(0, i), ...prev.slice(i + 1)];
                        }
                      })
                    }
                    sx={{
                      '@keyframes blinkOutline': {
                        '0%': {
                          outline: '2px solid rgba(255, 0, 0, 0)',
                        },
                        '50%': {
                          outline: '2px solid rgba(255, 0, 0, 1)',
                        },
                        '100%': {
                          outline: '2px solid rgba(255, 0, 0, 0)',
                        },
                      },
                      animation: highlightPersonnelAdd ? 'blinkOutline 2s infinite' : 'none',
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography
                        variant='body1'
                        color='primary.main'
                        textTransform='uppercase'
                        textAlign='center'
                      >
                        Manage Attendees (badges)
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        <Divider sx={{ mt: -1, mb: 1 }} />
                        <Button onClick={() => handleAddPersonnel(brand.id)} startIcon={<Add />}>
                          Add Attendees (badges)
                        </Button>
                        {brand.personnel.length > 0 && (
                          <>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                columnGap: 1,
                                mt: 2,
                              }}
                            >
                              <Typography variant='body1'>
                                Required columns are marked with (*) when editing.
                              </Typography>
                              <Typography variant='body1'>
                                Info with blue headers will be printed on badge.
                              </Typography>
                            </Box>
                            {brand.personnel.map((person) => (
                              <React.Fragment key={person.badge_id}>
                                <Table
                                  size='small'
                                  sx={{
                                    mt: 2,
                                    '& tbody tr td': {
                                      px: 0.5,
                                    },
                                  }}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell />
                                      <TableCell colSpan={6}>
                                        <Stack
                                          direction='row'
                                          justifyContent='center'
                                          gap={1}
                                          textAlign='center'
                                        >
                                          <Typography
                                            variant='body1'
                                            sx={{ color: '#00325A', fontWeight: 'bold' }}
                                          >
                                            ↓
                                          </Typography>
                                          <Typography
                                            variant='body1'
                                            sx={{ color: '#00325A', fontWeight: 'bold' }}
                                          >
                                            This info will be printed on badge
                                          </Typography>
                                          <Typography
                                            variant='body1'
                                            sx={{ color: '#00325A', fontWeight: 'bold' }}
                                          >
                                            ↓
                                          </Typography>
                                        </Stack>
                                      </TableCell>
                                      <TableCell colSpan={2} />
                                      {brand.personnel.some((person) =>
                                        editingRows.includes(person.badge_id)
                                      ) && <TableCell />}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell />
                                      <TableCell
                                        sx={{
                                          backgroundColor: '#00325A',
                                          textAlign: 'center',
                                          borderTopLeftRadius: '4px',
                                          borderBottomLeftRadius: '4px',
                                        }}
                                      >
                                        <Typography
                                          variant='body1'
                                          sx={{ color: 'white', fontWeight: 'bold' }}
                                        >
                                          {editingRows.includes(person.badge_id)
                                            ? 'First Name *'
                                            : 'First Name'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        sx={{ backgroundColor: '#00325A', textAlign: 'center' }}
                                      >
                                        <Typography
                                          variant='body1'
                                          sx={{ color: 'white', fontWeight: 'bold' }}
                                        >
                                          {editingRows.includes(person.badge_id)
                                            ? 'Last Name *'
                                            : 'Last Name'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        sx={{ backgroundColor: '#00325A', textAlign: 'center' }}
                                      >
                                        <Typography
                                          variant='body1'
                                          sx={{ color: 'white', fontWeight: 'bold' }}
                                        >
                                          Job Title
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        sx={{ backgroundColor: '#00325A', textAlign: 'center' }}
                                      >
                                        <Typography
                                          variant='body1'
                                          sx={{ color: 'white', fontWeight: 'bold' }}
                                        >
                                          {editingRows.includes(person.badge_id)
                                            ? 'Company Name *'
                                            : 'Company Name'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        sx={{ textAlign: 'center', backgroundColor: '#00325A' }}
                                      >
                                        <Typography
                                          variant='body1'
                                          sx={{ color: 'white', fontWeight: 'bold' }}
                                        >
                                          {editingRows.includes(person.badge_id)
                                            ? 'City *'
                                            : 'City'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          textAlign: 'center',
                                          backgroundColor: '#00325A',
                                          borderBottomRightRadius: '4px',
                                          borderTopRightRadius: '4px',
                                        }}
                                      >
                                        <Typography
                                          variant='body1'
                                          sx={{ color: 'white', fontWeight: 'bold' }}
                                        >
                                          {editingRows.includes(person.badge_id)
                                            ? 'State *'
                                            : 'State'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        sx={{ textAlign: 'center', backgroundColor: 'lightgray' }}
                                      >
                                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                          {editingRows.includes(person.badge_id)
                                            ? 'Email Address *'
                                            : 'Email Address'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        sx={{ textAlign: 'center', backgroundColor: 'lightgray' }}
                                      >
                                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                          Cell Phone #
                                        </Typography>
                                      </TableCell>
                                      {editingRows.includes(person.badge_id) && <TableCell />}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody
                                    sx={{
                                      '& tr:nth-last-of-type(1) td': {
                                        borderBottom: 'none',
                                      },
                                      '& td:not(:first-of-type)': {
                                        wordBreak: 'break-word',
                                      },
                                    }}
                                  >
                                    <TableRow>
                                      <TableCell sx={{ padding: '16px' }}>
                                        <Box
                                          display='flex'
                                          justifyContent='center'
                                          alignItems='center'
                                          columnGap={1}
                                        >
                                          <IconButton onClick={() => handleBadgeModal(person)}>
                                            <Stack alignItems='center'>
                                              <Image />
                                              <Typography variant='caption'>PREVIEW</Typography>
                                            </Stack>
                                          </IconButton>
                                          {!editingRows.includes(person.badge_id) && (
                                            <IconButton onClick={() => handleEdit(person.badge_id)}>
                                              <Stack alignItems='center'>
                                                <Edit />
                                                <Typography variant='caption'>EDIT</Typography>
                                              </Stack>
                                            </IconButton>
                                          )}
                                          <IconButton
                                            onClick={() =>
                                              deletePersonnel(brand.id, person.badge_id)
                                            }
                                          >
                                            <Stack alignItems='center'>
                                              <Delete />
                                              <Typography variant='caption'>DELETE</Typography>
                                            </Stack>
                                          </IconButton>
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        {editingRows.includes(person.badge_id) ? (
                                          <TextField
                                            fullWidth
                                            placeholder='First Name'
                                            value={person.first_name ?? ''}
                                            onChange={(e) =>
                                              handlePersonnelInputChange(
                                                brand.id,
                                                person.badge_id,
                                                'first_name',
                                                e.target.value
                                              )
                                            }
                                            required
                                            error={validateErrors[person.badge_id]?.first_name}
                                            onBlur={(e) =>
                                              updateValidationError(
                                                person.badge_id,
                                                'first_name',
                                                !e.target.value
                                              )
                                            }
                                          />
                                        ) : (
                                          <Typography variant='body1' textAlign='center'>
                                            {person.first_name}
                                          </Typography>
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {editingRows.includes(person.badge_id) ? (
                                          <TextField
                                            fullWidth
                                            placeholder='Last Name'
                                            value={person.last_name ?? ''}
                                            onChange={(e) =>
                                              handlePersonnelInputChange(
                                                brand.id,
                                                person.badge_id,
                                                'last_name',
                                                e.target.value
                                              )
                                            }
                                            required
                                            error={validateErrors[person.badge_id]?.last_name}
                                            onBlur={(e) =>
                                              updateValidationError(
                                                person.badge_id,
                                                'last_name',
                                                !e.target.value
                                              )
                                            }
                                          />
                                        ) : (
                                          <Typography variant='body1' textAlign='center'>
                                            {person.last_name}
                                          </Typography>
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {editingRows.includes(person.badge_id) ? (
                                          <TextField
                                            fullWidth
                                            placeholder='Job Title'
                                            value={person.job_title ?? ''}
                                            onChange={(e) =>
                                              handlePersonnelInputChange(
                                                brand.id,
                                                person.badge_id,
                                                'job_title',
                                                e.target.value
                                              )
                                            }
                                          />
                                        ) : (
                                          <Typography variant='body1' textAlign='center'>
                                            {person.job_title}
                                          </Typography>
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {editingRows.includes(person.badge_id) ? (
                                          <TextField
                                            fullWidth
                                            placeholder='Company Name'
                                            value={person.company_name ?? ''}
                                            onChange={(e) =>
                                              handlePersonnelInputChange(
                                                brand.id,
                                                person.badge_id,
                                                'company_name',
                                                e.target.value
                                              )
                                            }
                                            required
                                            error={validateErrors[person.badge_id]?.company_name}
                                            onBlur={(e) =>
                                              updateValidationError(
                                                person.badge_id,
                                                'company_name',
                                                !e.target.value
                                              )
                                            }
                                          />
                                        ) : (
                                          <Typography variant='body1' textAlign='center'>
                                            {person.company_name}
                                          </Typography>
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {editingRows.includes(person.badge_id) ? (
                                          <TextField
                                            fullWidth
                                            placeholder='City'
                                            value={person.city ?? ''}
                                            onChange={(e) =>
                                              handlePersonnelInputChange(
                                                brand.id,
                                                person.badge_id,
                                                'city',
                                                e.target.value
                                              )
                                            }
                                            required
                                            error={validateErrors[person.badge_id]?.city}
                                            onBlur={(e) =>
                                              updateValidationError(
                                                person.badge_id,
                                                'city',
                                                !e.target.value
                                              )
                                            }
                                          />
                                        ) : (
                                          <Typography variant='body1' textAlign='center'>
                                            {person.city}
                                          </Typography>
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {editingRows.includes(person.badge_id) ? (
                                          <Autocomplete
                                            options={states}
                                            getOptionLabel={(option) =>
                                              `${option.description} (${option.abbreviation})`
                                            }
                                            value={
                                              states.find(
                                                (state) => state.state_id === person.state_id
                                              ) ?? null
                                            }
                                            onChange={(e, value) => {
                                              handlePersonnelStateChange(
                                                brand.id,
                                                person.badge_id,
                                                value?.state_id ?? null,
                                                value?.abbreviation ?? null
                                              );
                                            }}
                                            onBlur={(e) =>
                                              updateValidationError(
                                                person.badge_id,
                                                'state_id',
                                                !e.target.value
                                              )
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder='State'
                                                sx={{ minWidth: '150px' }}
                                                required
                                                error={validateErrors[person.badge_id]?.state_id}
                                              />
                                            )}
                                          />
                                        ) : (
                                          <Typography variant='body1' textAlign='center'>
                                            {person.state}
                                          </Typography>
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {editingRows.includes(person.badge_id) ? (
                                          <TextField
                                            fullWidth
                                            placeholder='Email Address'
                                            value={person.email_address ?? ''}
                                            onChange={(e) =>
                                              handlePersonnelInputChange(
                                                brand.id,
                                                person.badge_id,
                                                'email_address',
                                                e.target.value
                                              )
                                            }
                                            required
                                            error={validateErrors[person.badge_id]?.email_address}
                                            onBlur={(e) =>
                                              updateValidationError(
                                                person.badge_id,
                                                'email_address',
                                                !e.target.value
                                              )
                                            }
                                          />
                                        ) : (
                                          <Typography variant='body1' textAlign='center'>
                                            {person.email_address}
                                          </Typography>
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {editingRows.includes(person.badge_id) ? (
                                          <MaskedInput
                                            mask={[
                                              '(',
                                              /[1-9]/,
                                              /\d/,
                                              /\d/,
                                              ')',
                                              ' ',
                                              /\d/,
                                              /\d/,
                                              /\d/,
                                              '-',
                                              /\d/,
                                              /\d/,
                                              /\d/,
                                              /\d/,
                                            ]}
                                            placeholder='(123) 456-7890'
                                            value={person.phone_number ?? ''}
                                            onChange={(e) =>
                                              handlePersonnelInputChange(
                                                brand.id,
                                                person.badge_id,
                                                'phone_number',
                                                e.target.value
                                              )
                                            }
                                            render={(ref, props) => (
                                              <TextField
                                                fullWidth
                                                placeholder='Cell Phone #'
                                                inputRef={ref}
                                                {...props}
                                              />
                                            )}
                                          />
                                        ) : (
                                          <Typography variant='body1' textAlign='center'>
                                            {formatPhoneNumber(person.phone_number)}
                                          </Typography>
                                        )}
                                      </TableCell>
                                      {editingRows.includes(person.badge_id) && (
                                        <TableCell sx={{ padding: '16px', whiteSpace: 'pre' }}>
                                          <IconButton
                                            onClick={() => {
                                              if (validateData(person)) {
                                                handleSave(brand.id, person.badge_id);
                                              }
                                            }}
                                          >
                                            <Stack alignItems='center'>
                                              <Save />
                                              <Typography variant='caption'>SAVE</Typography>
                                            </Stack>
                                          </IconButton>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  </TableBody>
                                </Table>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    py: editingRows.includes(person.badge_id) ? 0 : 2,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      gap: 2,
                                    }}
                                  >
                                    {editingRows.includes(person.badge_id) ? (
                                      <>
                                        <Box sx={{ textAlign: 'center' }}>
                                          <Typography variant='body1'>
                                            On Monday night, select manufacturers will be hosting
                                            private events throughout the downtown area. At which
                                            party would you like to start your evening?
                                          </Typography>
                                        </Box>
                                        <Autocomplete
                                          options={parties}
                                          getOptionLabel={(option) => option.description}
                                          value={
                                            parties.find(
                                              (party) => party.poi_id === person.poi_id
                                            ) ?? null
                                          }
                                          onChange={(e, value) => {
                                            handlePersonnelPoiChange(
                                              brand.id,
                                              person.badge_id,
                                              value?.poi_id ?? null,
                                              value?.description ?? null
                                            );
                                          }}
                                          onBlur={(e) =>
                                            updateValidationError(
                                              person.badge_id,
                                              'poi_id',
                                              !e.target.value
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder='Select Party *'
                                              sx={{ width: '300px', maxWidth: '100%' }}
                                              required
                                              error={validateErrors[person.badge_id]?.poi_id}
                                            />
                                          )}
                                        />
                                      </>
                                    ) : (
                                      <Box sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
                                        <Typography variant='body1'>
                                          On Monday night, select manufacturers will be hosting
                                          private events throughout the downtown area. You've chosen
                                          to start your evening at the party of:{'  '}
                                          <strong style={{ whiteSpace: 'pre' }}>
                                            {person.poi ?? 'None selected'}
                                          </strong>
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      gap: 2,
                                    }}
                                  >
                                    {editingRows.includes(person.badge_id) ? (
                                      <>
                                        <Box sx={{ textAlign: 'center' }}>
                                          <Typography variant='body1'>
                                            We invite you to experience a guided tour of our
                                            state-of-the-art warehouse during your visit. Please
                                            select your preferred tour time:
                                          </Typography>
                                        </Box>
                                        <Autocomplete
                                          options={tourTimes}
                                          getOptionLabel={(option) => option.description}
                                          value={
                                            tourTimes.find(
                                              (tt) => tt.tour_time_id === person.tour_time_id
                                            ) ?? null
                                          }
                                          onChange={(e, value) => {
                                            handlePersonnelTourTimeChange(
                                              brand.id,
                                              person.badge_id,
                                              value?.tour_time_id ?? null,
                                              value?.description ?? null
                                            );
                                          }}
                                          onBlur={(e) =>
                                            updateValidationError(
                                              person.badge_id,
                                              'tour_time_id',
                                              !e.target.value
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder='Select Tour Time *'
                                              sx={{ width: '300px', maxWidth: '100%' }}
                                              required
                                              error={validateErrors[person.badge_id]?.tour_time_id}
                                            />
                                          )}
                                        />
                                      </>
                                    ) : (
                                      <Box sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
                                        <Typography variant='body1'>
                                          We invite you to experience a guided tour of our
                                          state-of-the-art warehouse during your visit. You have
                                          selected your preferred tour time to be:
                                          {'  '}
                                          <strong style={{ whiteSpace: 'pre' }}>
                                            {person.tour_time ?? 'None selected'}
                                          </strong>
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                                <Divider sx={{ width: '100%', mt: 2 }} />
                              </React.Fragment>
                            ))}
                          </>
                        )}
                      </>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ))}
    </Paper>
  );
};

export default ARegistrationTableDesktop;
