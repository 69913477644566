import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Box, Button, ButtonBase, Typography, Divider, lighten } from '@mui/material';
import Ads from './Ads';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RotRLogo from '../Assets/rendezvousLogo.png';

const Home = () => {
  const [content, setContent] = useState(null);
  const userType = useSelector((state) => state.userInformation.value.userType);
  const navigate = useNavigate();

  const handleClick = () => {
    if (userType === 'G7h8I9j0K1l2') {
      navigate('/e-register');
    } else {
      navigate('/a-register');
    }
  };

  useEffect(() => {
    axios
      .get('/rotr/home/content')
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  return (
    <Grid container justifyContent='center' spacing={3}>
      <Grid item container xs={12} md={12}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            pb: '30%',
            height: 0,
            borderBottom: '4px solid #cb351a',
          }}
        >
          <img
            alt='Banner'
            src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/home_banner_red.jpg'
            style={{
              maxWidth: '100%',
            }}
          />
          <img
            alt='2025 Sports South Dealer Summit'
            src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/ROTR_overlay.png'
            style={{
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: '50%',
              filter: 'drop-shadow(0px 2px 4px black)',
            }}
          />
          <Button
            color='primary'
            onClick={handleClick}
            sx={{
              backgroundColor: '#00325a',
              '&:hover': {
                backgroundColor: lighten('#00325a', 0.2),
              },
              position: 'absolute',
              top: '85%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontWeight: 'bold',
              color: '#fff',
              border: '4px solid #fff',
              textTransform: 'none',
              fontSize: '2vw',
              padding: '4px 64px',
            }}
          >
            Register Now
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ButtonBase onClick={() => navigate('/flights')} style={{ width: '100%' }}>
          <Box width='100%' backgroundColor='rgb(220, 220, 220)'>
            <Grid container>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
              >
                <Typography
                  variant='h1'
                  sx={{
                    textAlign: { xs: 'center', md: 'justify' },
                    ml: { xs: 0, md: 2 },
                    color: '#00325a',
                    fontWeight: 'bold',
                    fontSize: { xs: '3em', md: '7em' },
                    lineHeight: 1.2,
                  }}
                >
                  Don't
                  <br /> settle
                  <br />
                  for less
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={8}
                direction='column'
                sx={{ boxSizing: 'border-box', p: 2 }}
              >
                <Grid item sx={{ mb: 2 }}>
                  <Box sx={{ maxWidth: '70%', textAlign: 'center' }}>
                    <img
                      src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/home_banner_overlay_blue.png'
                      alt='RotR Logo'
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                  </Box>
                </Grid>
                <Grid item sx={{ p: { xs: 1, md: 2 } }}>
                  <Typography
                    variant='body1'
                    sx={{ textAlign: 'justify', fontSize: { xs: '1rem', md: '1.2rem' } }}
                  >
                    This highly anticipated show is the must-attend event of the year, offering you
                    the perfect opportunity to connect with your sales reps and network with
                    hundreds of manufacturers. From unbeatable bargains to delicious Cajun cuisine,
                    fantastic music, and HUGE giveaways, this is the show you need to be ready for.
                    Book your flight now before it's too late - don't miss out on the chance to
                    elevate your business!
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant='h3'
                    sx={{
                      color: '#cb351a',
                      fontWeight: 'bold',
                      textAlign: 'justify',
                      fontSize: { xs: '2rem', md: '4rem' },
                    }}
                  >
                    Book your flight today!
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </ButtonBase>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ backgroundColor: '#cb351a', height: 4, borderRadius: '8px' }} />
      </Grid>
      {/* <Ads numberToShow={3} gridXS={4} /> */}
      <Grid item xs={12}>
        <Divider sx={{ backgroundColor: '#cb351a', height: 4, borderRadius: '8px' }} />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            borderRadius: '8px',
            overflow: 'hidden',
            // cursor: 'pointer',
          }}
          // onClick={() => window.open('https://www.rose.org/visit-public-gardens', '_blank')}
        >
          <img
            src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/warehouse_o.jpg'
            alt='Sports South Warehouse'
            style={{ width: '100%', height: '500px', objectFit: 'cover', display: 'block' }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '0',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '80%',
              bgcolor: '#cb351a',
              color: 'white',
              textAlign: 'center',
              p: '5px 0',
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='caption'
              sx={{
                fontFamily: 'League Spartan',
                fontSize: '1.2rem',
                display: 'block',
                lineHeight: 1.2,
                fontWeight: 'bold',
              }}
            >
              First look at the expansion of our state-of-the-art automated warehouse!
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            borderRadius: '8px',
            overflow: 'hidden',
            // cursor: 'pointer',
          }}
          // onClick={() => window.open('https://www.rose.org/visit-public-gardens', '_blank')}
        >
          <img
            src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/deals.jpg'
            alt='Deals'
            style={{ width: '100%', height: '200px', objectFit: 'cover', display: 'block' }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '0',
              right: '0',
              width: '60%',
              bgcolor: '#cb351a',
              color: 'white',
              textAlign: 'center',
              p: '5px 0',
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='caption'
              sx={{
                fontFamily: 'League Spartan',
                fontSize: '1.2rem',
                display: 'block',
                lineHeight: 1.2,
                fontWeight: 'bold',
              }}
            >
              Unbeatable Deals
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            borderRadius: '8px',
            overflow: 'hidden',
            // cursor: 'pointer',
          }}
          // onClick={() => window.open('https://www.rose.org/visit-public-gardens', '_blank')}
        >
          <img
            src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/food.jpg'
            alt='Food'
            style={{ width: '100%', height: '200px', objectFit: 'cover', display: 'block' }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '0',
              right: '0',
              width: '60%',
              bgcolor: '#cb351a',
              color: 'white',
              textAlign: 'center',
              p: '5px 0',
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='caption'
              sx={{
                fontFamily: 'League Spartan',
                fontSize: '1.2rem',
                display: 'block',
                lineHeight: 1.2,
                fontWeight: 'bold',
              }}
            >
              Great Food
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            borderRadius: '8px',
            overflow: 'hidden',
            // cursor: 'pointer',
          }}
          // onClick={() => window.open('https://www.rose.org/visit-public-gardens', '_blank')}
        >
          <img
            src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/music.jpg'
            alt='Giveaways'
            style={{ width: '100%', height: '200px', objectFit: 'cover', display: 'block' }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '0',
              right: '0',
              width: '60%',
              bgcolor: '#cb351a',
              color: 'white',
              textAlign: 'center',
              p: '5px 0',
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='caption'
              sx={{
                fontFamily: 'League Spartan',
                fontSize: '1.2rem',
                display: 'block',
                lineHeight: 1.2,
                fontWeight: 'bold',
              }}
            >
              Fantastic Music
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            borderRadius: '8px',
            overflow: 'hidden',
            // cursor: 'pointer',
          }}
          // onClick={() => window.open('https://www.rose.org/visit-public-gardens', '_blank')}
        >
          <img
            src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/giveaways.jpg'
            alt='Music'
            style={{ width: '100%', height: '200px', objectFit: 'cover', display: 'block' }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '0',
              right: '0',
              width: '60%',
              bgcolor: '#cb351a',
              color: 'white',
              textAlign: 'center',
              p: '5px 0',
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='caption'
              sx={{
                fontFamily: 'League Spartan',
                fontSize: '1.2rem',
                display: 'block',
                lineHeight: 1.2,
                fontWeight: 'bold',
              }}
            >
              HUGE Giveaways
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ backgroundColor: '#cb351a', height: 4, borderRadius: '8px' }} />
      </Grid>
      {/* <Ads numberToShow={3} gridXS={4} /> */}
      <Grid item xs={12}>
        <Divider sx={{ backgroundColor: '#cb351a', height: 4, borderRadius: '8px' }} />
      </Grid>
      <Grid item xs={12}>
        <ButtonBase onClick={() => navigate('/hotels')} style={{ width: '100%' }}>
          <Box width='100%' backgroundColor='rgb(220, 220, 220)'>
            <Grid container>
              <Grid
                item
                container
                xs={12}
                md={8}
                direction='column'
                sx={{ boxSizing: 'border-box', p: 2 }}
              >
                <Grid item sx={{ mb: 2 }}>
                  <Box sx={{ maxWidth: '100%', textAlign: 'left', pl: { xs: 1, md: 2 } }}>
                    <img
                      src={RotRLogo}
                      alt='RotR Logo'
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                  </Box>
                </Grid>
                <Grid item sx={{ p: { xs: 1, md: 2 } }}>
                  <Typography
                    variant='body1'
                    sx={{ textAlign: 'justify', fontSize: { xs: '1rem', md: '1.2rem' } }}
                  >
                    Get ready for the most anticipated event of the year! This show is your prime
                    opportunity to connect with sales representatives and network with hundreds of
                    manufacturers. Take advantage of unbeatable deals, savor mouth-watering Cajun
                    cuisine, enjoy fantastic music, and win serious giveaways. Book your hotel now
                    for the best availability and be part of this exciting experience. Elevate your
                    business and don't miss out—secure your stay today!
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant='h3'
                    sx={{
                      color: '#cb351a',
                      fontWeight: 'bold',
                      textAlign: 'justify',
                      fontSize: { xs: '2rem', md: '4rem' },
                      pl: { xs: 1, md: 2 },
                    }}
                  >
                    Reserve your hotel now!
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
              >
                <Typography
                  variant='h1'
                  sx={{
                    textAlign: { xs: 'center', md: 'end' },
                    ml: { xs: 0, md: 2 },
                    color: '#00325a',
                    fontWeight: 'bold',
                    fontSize: { xs: '3em', md: '7em' },
                    lineHeight: 1.2,
                    pr: 3,
                  }}
                >
                  Set your
                  <br />
                  sights
                  <br />
                  higher
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </ButtonBase>
      </Grid>
    </Grid>
  );
};

export default Home;
