import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  styled,
  Box,
} from '@mui/material';
import axios from 'axios';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: 'auto',
  marginBottom: theme.spacing(2),
}));

const StyledCardMedia = styled(CardMedia)({
  height: 140,
});

const AttendeeResourceCenter = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    axios
      .get(`/rotr/attendees/resourceCenterContent`)
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  return (
    <Box sx={{ padding: '16px' }}>
      <Grid container justifyContent='center'>
        <Grid item container xs={12} md={12} id='ATTENDEE_RESOURCE_ROW1_COL1' />
        <StyledContainer>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <StyledCard>
                <StyledCardMedia
                  image='https://via.placeholder.com/345x140'
                  title='Event Schedule'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Event Schedule
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Access the full event schedule to plan your attendance and participate in
                    sessions and workshops.
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledCard>
                <StyledCardMedia
                  image='https://via.placeholder.com/345x140'
                  title='Speaker Profiles'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Speaker Profiles
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Learn more about the speakers at the event, including their backgrounds and
                    topics they will cover.
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledCard>
                <StyledCardMedia image='https://via.placeholder.com/345x140' title='Venue Map' />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Venue Map
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    View the map of the venue to easily navigate and find the sessions and
                    exhibitors you want to visit.
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledCard>
                <StyledCardMedia
                  image='https://via.placeholder.com/345x140'
                  title='Attendee Guidelines'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Attendee Guidelines
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Read through the guidelines for attendees to ensure a safe and enjoyable
                    experience for everyone.
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>
        </StyledContainer>
      </Grid>
    </Box>
  );
};

export default AttendeeResourceCenter;
