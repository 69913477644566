import React, { useEffect, useState } from 'react';
import {
  Box,
  Modal,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import axios from 'axios';
import { alpha } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { setModalStatus } from '../../../Redux/ModalSlice';

const BoothTypeModal = () => {
  const [boothOptions, setBoothOptions] = useState([]);
  const [images, setImages] = useState({
    bronze: null,
    silver: null,
    gold: null,
    platinum: null,
  });

  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.modalStatus.boothModal);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showButton = useSelector((state) => state.modalStatus.boothModalButton);

  useEffect(() => {
    if (isModalOpen) {
      fetchBoothOptions();
    }
  }, [isModalOpen]);

  const fetchBoothOptions = async () => {
    try {
      const response = await axios.get('/rotr/e-registration/booth/content');
      const data = response.data.filter((option) => option.title !== 'img');
      const imageData = response.data.find((option) => option.title === 'img');
      setBoothOptions(data);
      if (imageData) {
        setImages({
          bronze: imageData.bronze
            ? `https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/${imageData.bronze}`
            : null,
          silver: imageData.silver
            ? `https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/${imageData.silver}`
            : null,
          gold: imageData.gold
            ? `https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/${imageData.gold}`
            : null,
          platinum: imageData.platinum
            ? `https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/${imageData.platinum}`
            : null,
        });
      }
    } catch (error) {
      console.error('Error fetching booth options:', error);
    }
  };

  const renderOption = (option) => {
    if (option === 'true')
      return (
        <CheckCircleIcon
          sx={{ color: '#00325a', verticalAlign: 'middle', fontSize: isMobile ? '1rem' : '1.5rem' }}
        />
      );
    if (option === 'false') return '--';
    return option;
  };

  const handleSelectBooth = () => {
    window.open('https://rotr.expofp.com/', '_self');
  };

  return (
    <Modal open={isModalOpen} onClose={() => dispatch(setModalStatus({ boothModal: false }))}>
      <Box sx={{ ...modalStyle, width: isMobile ? '100%' : '80%', maxWidth: 1000 }}>
        <Typography
          variant='h4'
          gutterBottom
          align='center'
          sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}
        >
          Booth Options
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ overflowX: 'auto', width: '100%' }}>
          <Table size='small' sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {['bronze', 'silver', 'gold', 'platinum'].map((type, index) => (
                  <TableCell key={index} align='center' sx={{ px: 0, verticalAlign: 'bottom' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {images[type] && (
                        <img
                          src={images[type]}
                          alt={`${type} booth`}
                          style={{
                            width: isMobile ? '50px' : '125px',
                            height: 'auto',
                            marginBottom: '5px',
                          }}
                        />
                      )}
                      <Typography variant='body2'>
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {boothOptions.map((option, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '24px' }}
                >
                  <TableCell sx={{ py: 0 }}>{option.title}</TableCell>
                  {['bronze', 'silver', 'gold', 'platinum'].map((type) => (
                    <TableCell
                      key={type}
                      align='center'
                      sx={{
                        py: 0,
                        verticalAlign: 'middle',
                        fontSize: isMobile ? '0.75rem' : '1rem',
                      }}
                    >
                      {renderOption(option[type])}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        {showButton && (
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Button
              variant='contained'
              onClick={handleSelectBooth}
              sx={{
                mt: 2,
                backgroundColor: '#00325a',
                '&:hover': {
                  backgroundColor: alpha('#00325a', 0.6),
                },
              }}
            >
              Select Your Booth
            </Button>
            <Typography variant='body2' sx={{ mt: 1, px: 1 }}>
              By clicking the button, you will navigate to a separate page. You will be brought back
              upon finalizing your selection.
            </Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  maxHeight: '80vh',
  overflowY: 'auto',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: { xs: 2, md: 4 },
  borderRadius: 2,
};

export default BoothTypeModal;
