import React from 'react';
import { Modal, Paper, Typography, Button, Box, Divider } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { alpha } from '@mui/material/styles';
import { motion } from 'framer-motion';

const ConfirmDeleteModal = ({ open, handleClose, handleDelete, bigText = '', smallText = '' }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
      >
        <Paper
          sx={{
            p: 4,
            maxWidth: 500,
            width: '100%',
            borderRadius: 3,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#f7f8fa',
          }}
        >
          <Typography
            variant='h5'
            sx={{
              fontWeight: 'bold',
              marginBottom: 2,
              textAlign: 'center',
              color: '#00325a',
              fontSize: '1.75rem',
            }}
          >
            Confirm Deletion
          </Typography>
          <Divider sx={{ marginBottom: 3 }} />

          <Typography variant='h6' sx={{ marginBottom: 3, textAlign: 'center' }}>
            {bigText}
          </Typography>
          <Typography variant='body1' sx={{ marginBottom: 3, textAlign: 'center' }}>
            {smallText}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleClose}
              startIcon={<CancelIcon />}
              sx={{
                backgroundColor: '#cb351a',
                '&:hover': {
                  backgroundColor: alpha('#cb351a', 0.8),
                },
                borderRadius: 2,
                fontWeight: 'bold',
                color: '#fff',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                textTransform: 'none',
                fontSize: '1rem',
                padding: '8px 16px',
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='error'
              onClick={handleDelete}
              startIcon={<DeleteIcon />}
              sx={{
                backgroundColor: '#00325a',
                '&:hover': {
                  backgroundColor: alpha('#00325a', 0.8),
                },
                borderRadius: 2,
                fontWeight: 'bold',
                color: '#fff',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                textTransform: 'none',
                fontSize: '1rem',
                padding: '8px 16px',
              }}
            >
              Delete
            </Button>
          </Box>
        </Paper>
      </motion.div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
