import axios from 'axios';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import InvoiceEmailLayout from './InvoiceEmailLayout';

const InvoiceEmail = (emailAddress, details) => {
  const htmlEncodedString = ReactDOMServer.renderToStaticMarkup(
    <InvoiceEmailLayout details={details} />
  );
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlEncodedString, 'text/html');
  const htmlContent = doc.body.textContent;

  const formData = new FormData();
  formData.append('emailAddress', emailAddress);
  formData.append('subject', `Rendezvous on the Red Booth Confirmation: ${details.booth}`);
  formData.append('htmlContent', htmlContent);

  return new Promise((resolve, reject) => {
    axios
      .post('/rotr/invoiceEmail', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default InvoiceEmail;
