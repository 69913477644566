import './App.css';
import axios from 'axios';
import RendezvousOnTheRed from './RendezvousOnTheRed/RendezvousOnTheRed';

function App() {
  if (window.location.host !== 'localhost:3000') {
    axios.defaults.baseURL = 'https://node.sportssouth.dev';
  }

  return <RendezvousOnTheRed />;
}

export default App;
