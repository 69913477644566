import { Modal, Paper, Divider, Typography, Box, Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '../../../Redux/ModalSlice';
import { motion } from 'framer-motion';

const ProfileExplanationModal = ({ link }) => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.modalStatus.profileExplanation);

  return (
    <Modal
      open={isModalOpen}
      onClose={() => dispatch(setModalStatus({ profileExplanation: false }))}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
      >
        <Paper
          sx={{
            p: 4,
            maxWidth: 500,
            width: '100%',
            borderRadius: 3,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#f7f8fa',
          }}
        >
          <Typography
            variant='h5'
            sx={{
              fontWeight: 'bold',
              marginBottom: 2,
              textAlign: 'center',
              color: '#00325a',
              fontSize: '1.75rem',
            }}
          >
            Exhibitor Profile
          </Typography>
          <Divider sx={{ marginBottom: 3 }} />

          <Typography
            variant='h6'
            sx={{
              marginBottom: 3,
              textAlign: 'center',
              padding: '0 16px',
            }}
          >
            Enhance your visibility at the event by completing your exhibitor profile!
          </Typography>
          <Typography
            variant='body1'
            sx={{
              marginBottom: 3,
              textAlign: 'justify',
              padding: '0 16px',
            }}
          >
            This profile will be showcased on the final floor plan for all attendees to see. Take a
            moment to provide as much information as possible to make a great impression.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              variant='contained'
              color='error'
              sx={{ flex: 1, marginRight: 1 }}
              onClick={() => dispatch(setModalStatus({ profileExplanation: false }))}
            >
              I'll Do This Later
            </Button>
            <Button
              variant='contained'
              color='success'
              sx={{ flex: 1, marginLeft: 1 }}
              onClick={() => {
                window.open(`https://www.expofp.com${link}`, '_blank');
                dispatch(setModalStatus({ profileExplanation: false }));
              }}
            >
              Take Me There
            </Button>
          </Box>
        </Paper>
      </motion.div>
    </Modal>
  );
};

export default ProfileExplanationModal;
