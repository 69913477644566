import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../Redux/UserInformationSlice';

const Footer = () => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    Cookies.remove('access-code');
    await new Promise((resolve) => {
      dispatch(logoutUser());
      resolve();
    });
    window.location.reload();
  };

  return (
    <AppBar position='static' sx={{ backgroundColor: '#ffffff', mt: 2 }}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters variant='dense' sx={{ justifyContent: 'space-between' }}>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              display: 'flex',
              cursor: 'pointer',
            }}
            onClick={handleLogout}
          >
            <Typography variant='subtitle1' sx={{ color: '#00325a', whiteSpace: 'pre' }}>
              © {new Date().getFullYear()} SPORTS SOUTH, LLC
            </Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Footer;
