import { createSlice } from '@reduxjs/toolkit';

export const AttendeeSlice = createSlice({
  name: 'attendees',
  initialState: {
    value: [],
  },
  reducers: {
    setAttendees: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setAttendees } = AttendeeSlice.actions;
export default AttendeeSlice.reducer;
