import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import RegistrationHeader from './RegistrationHeader';
import RegistrationContent from './RegistrationContent';
import ConfirmModal from './ConfirmModal';
import BoothTypeModal from './BoothTypeModal';
import { alpha } from '@mui/material/styles';
import banner from '../../Assets/banner.png';
import { useDispatch, useSelector } from 'react-redux';
import { setExhibitorRegistration } from '../../../Redux/ExhibitorRegistrationSlice';
import { setModalStatus } from '../../../Redux/ModalSlice';
import { useNavigate } from 'react-router-dom';

const RegistrationContainer = ({ userInfo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const isAddingBooth = useSelector((state) => state.modalStatus.isAddingBooth);
  const isEditingBooth = useSelector((state) => state.registration.isEditing);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const booth = params.get('booth');
    const type = params.get('type');
    const price = params.get('price');

    if (booth && type && price) {
      dispatch(setModalStatus({ isAddingBooth: true }));
      dispatch(
        setExhibitorRegistration({ boothNumber: booth, boothType: type, boothPrice: price })
      );

      // Clear URL parameters
      navigate(window.location.pathname, { replace: true });
    } else {
      setIsLoading(false);
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (isAddingBooth || isEditingBooth) {
      setIsLoading(false);
    }
  }, [isAddingBooth, isEditingBooth]);

  return (
    <>
      {!isLoading && (
        <>
          {isAddingBooth || isEditingBooth ? (
            <Paper
              elevation={3}
              sx={{
                p: 2,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: '#f8f9fa',
                // mx: 4,
              }}
            >
              <Paper sx={{ p: 2, borderRadius: 2 }}>
                <Grid container sx={{ textAlign: 'center' }}>
                  <Grid item xs={12}>
                    <RegistrationHeader />
                  </Grid>
                  <Grid item xs={12} justifyContent='center'>
                    <Divider sx={{ mt: 2, backgroundColor: '#00325a' }} />
                  </Grid>
                  <Grid item container xs={12} justifyContent='center'>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        mt={2}
                        gap={4}
                      >
                        <Box
                          display='flex'
                          alignItems='center'
                          justifyContent='center'
                          onClick={() =>
                            window.open(
                              'https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/addon_marketing_packet.pdf'
                            )
                          }
                          sx={{ cursor: 'pointer' }}
                          gap={0.5}
                        >
                          <Typography
                            variant='body1'
                            sx={{ color: '#00325a', textDecoration: 'underline' }}
                          >
                            View Reference Guide
                          </Typography>
                        </Box>

                        <Box
                          display='flex'
                          alignItems='center'
                          justifyContent='center'
                          sx={{ cursor: 'pointer' }}
                          onClick={() =>
                            dispatch(setModalStatus({ boothModal: true, boothModalButton: false }))
                          }
                        >
                          <Typography
                            variant='body1'
                            sx={{ color: '#00325a', textDecoration: 'underline' }}
                          >
                            View Booth Comparison
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <RegistrationContent />
                  </Grid>
                </Grid>
              </Paper>
            </Paper>
          ) : (
            <Box
              sx={{
                backgroundImage: `url(${banner})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
                minHeight: '30vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                p: 4,
                // mx: 4,
                backgroundRepeat: 'no-repeat',
              }}
            >
              <Paper
                elevation={8}
                sx={{
                  p: 4,
                  borderRadius: 2,
                  backgroundColor: alpha('#f8f9fa', 0.8),
                  width: {
                    xs: '90%',
                    sm: '70%',
                    md: '50%',
                    lg: '40%',
                  },
                  textAlign: 'center',
                }}
              >
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  direction='column'
                  sx={{ mt: 2, mb: 2 }}
                >
                  <Grid item>
                    <Typography
                      variant='h4'
                      gutterBottom
                      sx={{ fontWeight: 'bold', lineHeight: 1.2, color: '#00325a' }}
                    >
                      Welcome to Booth Registration
                    </Typography>
                    <Typography
                      variant='body2'
                      gutterBottom
                      sx={{ fontWeight: 'bold', lineHeight: 1.2, color: '#00325a' }}
                    >
                      Click the button below to start registering your booth for the event.
                    </Typography>
                    <Button
                      variant='contained'
                      onClick={() =>
                        dispatch(setModalStatus({ boothModal: true, boothModalButton: true }))
                      }
                      sx={{
                        backgroundColor: '#00325a',
                        '&:hover': {
                          backgroundColor: alpha('#00325a', 0.6),
                        },
                        mt: 2,
                      }}
                    >
                      Add Booth
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          )}
        </>
      )}
      <ConfirmModal userInfo={userInfo} />
      <BoothTypeModal />
    </>
  );
};

export default RegistrationContainer;
