import { createSlice } from '@reduxjs/toolkit';

export const BlockNavigationSlice = createSlice({
  name: 'blockNavigation',
  initialState: {
    origin: '',
    when: false,
    message: '',
  },
  reducers: {
    setOrigin: (state, action) => {
      return { ...state, origin: action.payload };
    },
    setWhen: (state, action) => {
      return { ...state, when: action.payload };
    },
    setMessage: (state, action) => {
      return { ...state, message: action.payload };
    },
    setBlockNavigation: (state, action) => {
      return {
        ...state,
        origin: action.payload.origin,
        when: action.payload.when,
        message: action.payload.message,
      };
    },
    clearBlockNavigation: (state) => {
      return {
        ...state,
        origin: '',
        when: false,
        message: '',
      };
    },
  },
});

export const { setOrigin, setWhen, setMessage, setBlockNavigation, clearBlockNavigation } =
  BlockNavigationSlice.actions;
export default BlockNavigationSlice.reducer;
