import React, { useMemo, useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  FormControl,
  FormHelperText,
  Typography,
  Paper,
} from '@mui/material';
import { Save, Edit } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import MaskedInput from 'react-text-mask';

const UserInfo = ({ userInfo, setUserInfo, handleSave }) => {
  const [errors, setErrors] = useState(
    userInfo?.firstName ||
      userInfo?.lastName ||
      userInfo?.companyName ||
      userInfo?.email ||
      userInfo?.phone
      ? {
          firstName: !userInfo.firstName,
          lastName: !userInfo.lastName,
          companyName: !userInfo.companyName,
          email: !userInfo.email,
          phone: !userInfo.phone,
        }
      : {
          firstName: false,
          lastName: false,
          companyName: false,
          email: false,
          phone: false,
        }
  );

  const [isEditing, setIsEditing] = useState(
    !(
      userInfo?.firstName &&
      userInfo?.lastName &&
      userInfo?.companyName &&
      userInfo?.email &&
      userInfo?.phone
    )
  );

  const handleChange = (field, value) => {
    setUserInfo({ ...userInfo, [field]: value });
    setErrors({ ...errors, [field]: !value });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return phoneRegex.test(phone);
  };

  const handleBlur = (event, field) => {
    setErrors({ ...errors, [field]: !event.target.value });
  };

  const handleEmailBlur = () => {
    setErrors({ ...errors, email: !isValidEmail(userInfo.email) });
  };

  const handlePhoneBlur = () => {
    setErrors({ ...errors, phone: !isValidPhoneNumber(userInfo.phone) });
  };

  const isSaveDisabled = useMemo(() => {
    return !(
      userInfo.firstName &&
      userInfo.lastName &&
      userInfo.companyName &&
      userInfo.email &&
      userInfo.phone
    );
  }, [userInfo]);

  const getError = (field) => {
    return errors[field];
  };

  const handleButtonClick = () => {
    if (isEditing) {
      handleSave()
        .then((res) => {
          setIsEditing(!isEditing);
        })
        .catch((err) => {
          console.error('Failed to update:', err);
        });
    } else {
      setIsEditing(!isEditing);
    }
  };

  return (
    <Grid item xs={12} md={12}>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: '#f8f9fa',
          // mx: 4,
          mt: 2,
        }}
      >
        <Typography variant='h6' textAlign='center' color='#00325a' fontWeight={600} mb={2}>
          Your Contact Information
        </Typography>
        {isEditing ? (
          <Grid container justifyContent='center' alignItems='center' spacing={2}>
            <Grid item xs={12} md={2.2}>
              <TextField
                fullWidth
                label='First Name'
                value={userInfo.firstName}
                onChange={(e) => handleChange('firstName', e.target.value)}
                onBlur={(e) => handleBlur(e, 'firstName')}
                error={getError('firstName')}
                helperText={getError('firstName') ? 'First Name is required' : ' '}
                required
                InputProps={{ sx: { backgroundColor: 'white' } }}
              />
            </Grid>
            <Grid item xs={12} md={2.2}>
              <TextField
                fullWidth
                label='Last Name'
                value={userInfo.lastName}
                onChange={(e) => handleChange('lastName', e.target.value)}
                onBlur={(e) => handleBlur(e, 'lastName')}
                error={getError('lastName')}
                helperText={getError('lastName') ? 'Last Name is required' : ' '}
                required
                InputProps={{ sx: { backgroundColor: 'white' } }}
              />
            </Grid>
            <Grid item xs={12} md={2.2}>
              <TextField
                fullWidth
                label='Company Name'
                value={userInfo.companyName}
                onChange={(e) => handleChange('companyName', e.target.value)}
                onBlur={(e) => handleBlur(e, 'companyName')}
                error={getError('companyName')}
                helperText={getError('companyName') ? 'Company Name is required' : ' '}
                required
                InputProps={{ sx: { backgroundColor: 'white' } }}
              />
            </Grid>
            <Grid item xs={12} md={2.2}>
              <TextField
                fullWidth
                label='Email Address'
                value={userInfo.email}
                onChange={(e) => handleChange('email', e.target.value)}
                onBlur={handleEmailBlur}
                error={getError('email')}
                helperText={getError('email') ? 'Email is required and should be valid' : ' '}
                required
                InputProps={{ sx: { backgroundColor: 'white' } }}
              />
            </Grid>
            <Grid item xs={12} md={2.2}>
              <MaskedInput
                mask={[
                  '(',
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                placeholder='(123) 456-7890'
                value={userInfo.phone}
                onChange={(e) => handleChange('phone', e.target.value)}
                onBlur={handlePhoneBlur}
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label='Phone #'
                    inputRef={ref}
                    {...props}
                    error={getError('phone')}
                    helperText={getError('phone') ? 'Phone # is required and should be valid' : ' '}
                    required
                    InputProps={{ sx: { backgroundColor: 'white' } }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <FormControl fullWidth>
                <Button
                  variant='contained'
                  startIcon={<Save />}
                  onClick={handleButtonClick}
                  disabled={isSaveDisabled}
                  sx={{
                    backgroundColor: '#00325a',
                    '&:hover': { backgroundColor: alpha('#00325a', 0.6) },
                  }}
                >
                  Save
                </Button>
                <FormHelperText> </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={{ xs: 0, md: 2 }}
            justifyContent='center'
            alignItems='center'
            textAlign='center'
          >
            <Grid
              container
              item
              xs={12}
              md='auto'
              flexDirection='row'
              justifyContent='space-between'
              columnGap={1}
            >
              <Typography variant='body1'>
                <strong>Name:</strong>
              </Typography>
              <Typography variant='body1'>
                {userInfo.firstName} {userInfo.lastName}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md='auto'
              flexDirection='row'
              justifyContent='space-between'
              columnGap={1}
            >
              <Typography variant='body1'>
                <strong>Company Name:</strong>
              </Typography>
              <Typography variant='body1'>{userInfo.companyName}</Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md='auto'
              flexDirection='row'
              justifyContent='space-between'
              columnGap={1}
            >
              <Typography variant='body1'>
                <strong>Email Address:</strong>
              </Typography>
              <Typography variant='body1'>{userInfo.email}</Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md='auto'
              flexDirection='row'
              justifyContent='space-between'
              columnGap={1}
            >
              <Typography variant='body1'>
                <strong>Phone #:</strong>
              </Typography>
              <Typography variant='body1'>{userInfo.phone}</Typography>
            </Grid>
            <Grid item xs={12} md='auto'>
              <FormControl>
                <Button
                  variant='contained'
                  startIcon={<Edit />}
                  onClick={handleButtonClick}
                  sx={{
                    mt: { xs: 2, md: 0 },
                    backgroundColor: '#00325a',
                    '&:hover': { backgroundColor: alpha('#00325a', 0.6) },
                  }}
                >
                  Edit
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default UserInfo;
