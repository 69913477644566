import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Paper, Container, useMediaQuery } from '@mui/material';
import axios from 'axios';
import EmbeddedVideo from '../../../_GlobalComponents/EmbeddedVideo';
import { Card, styled, CardMedia, CardContent, Button } from '@mui/material';
import parse from 'html-react-parser';
import { useTheme } from '@emotion/react';

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 400,
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[3],
  width: '100%',
  backgroundColor: 'transparent',
}));

const StyledCardMedia = styled(CardMedia)({
  height: 220,
});

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const DynamicSponsors = ({ sponsor }) => {
  const [data, setData] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const getContent = axios.get('/rotr/sponsors/content', {
      params: { sponsor: sponsor },
    });
    const getHeaderBanner = axios.get('/rotr/sponsors/header-banner', {
      params: { sponsor: sponsor },
    });
    const getBanners = axios.get('/rotr/sponsors/banners', {
      params: { sponsor: sponsor },
    });

    Promise.allSettled([getContent, getHeaderBanner, getBanners])
      .then((res) => {
        setData({
          content: res[0]?.value?.data ?? null,
          headerBanner: res[1]?.value?.data ?? null,
          banners: res[2]?.value?.data ?? null,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [sponsor]);

  const [loaded, setLoaded] = useState({
    headerBanner: false,
    video: false,
    logo: false,
    banners: new Array(data?.banners?.length).fill(false),
  });
  const [allLoaded, setAllLoaded] = useState(false);

  useEffect(() => {
    const allLoaded = Object.entries(loaded).every(([key, value]) => {
      return Array.isArray(value)
        ? value.every(Boolean) || data?.[key]?.length === 0
        : value || data?.[key]?.length === 0;
    });
    if (allLoaded) {
      setAllLoaded(true);
    }
  }, [loaded, data]);

  const handleLoad = (type, index = null) => {
    setLoaded((prev) => {
      if (type === 'banners') {
        const updatedBanners = [...prev.banners];
        updatedBanners[index] = true;
        return { ...prev, banners: updatedBanners };
      }
      return { ...prev, [type]: true };
    });
  };

  if (!data) {
    return null;
  }

  return (
    <Grid container spacing={2} sx={allLoaded ? null : { display: 'none' }}>
      {data.headerBanner?.length > 0 && (
        <Grid
          item
          container
          xs={12}
          md={12}
          sx={{
            alignContent: 'center',
          }}
        >
          <img
            src={data.headerBanner[0].header_banner_url}
            alt='Sponsor'
            style={{
              width: '100%',
              height: 'auto',
            }}
            onLoad={() => handleLoad('headerBanner')}
            onError={() => handleLoad('headerBanner')}
          />
        </Grid>
      )}

      {data.content?.length > 0 && (
        <>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                position: 'relative',
                height: { xs: '100%', md: '100%' },
                borderRadius: '16px',
              }}
              elevation={3}
            >
              <EmbeddedVideo
                src={data.content[0].video_url}
                onLoad={() => handleLoad('video')}
                onError={() => handleLoad('video')}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                position: 'relative',
                height: { xs: '100%', md: '100%' },
                borderRadius: '16px',
                backgroundColor: '#f5f5f5',
              }}
              elevation={3}
            >
              <CardContent
                sx={{
                  position: { xs: null, md: 'absolute' },
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  overflow: 'auto',
                  p: 2,
                  px: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    maxHeight: '40%',
                    marginBottom: '2rem',
                    marginTop: '2rem',
                  }}
                >
                  <img
                    src={data.content[0].logo_url}
                    alt='Logo'
                    style={{
                      width: isMobile ? 'auto' : '100%',
                      height: isMobile ? '200px' : '100%',
                    }}
                    onLoad={() => handleLoad('logo')}
                    onError={() => handleLoad('logo')}
                  />
                </Box>
                <Typography
                  variant='body1'
                  align='justify'
                  sx={{
                    width: '100%',
                    lineHeight: '1.6',
                    fontFamily: 'League Spartan',
                    fontSize: '1.1rem',
                  }}
                >
                  {parse(data.content[0].detailed_description)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}

      {data.banners?.length > 0 &&
        data.banners.map((banner, index) => (
          <Grid key={banner.banner_id} item container xs={12} md={3} justifyContent='center'>
            <a href={banner.banner_link_url} target='_blank' rel='noopener noreferrer'>
              <StyledCard>
                <StyledCardMedia
                  component='img'
                  src={banner.banner_location_url}
                  alt='Banner'
                  onLoad={() => handleLoad('banners', index)}
                  onError={() => handleLoad('banners', index)}
                  sx={{ height: '100%' }}
                />
              </StyledCard>
            </a>
          </Grid>
        ))}
    </Grid>
  );
};

export default DynamicSponsors;
