import React, { useState } from 'react';
import { Grid, Box, Typography, Divider, AppBar, Button, ButtonBase } from '@mui/material';
import { OpenInNew, ReadMore } from '@mui/icons-material';
import banner from '../Assets/banner.png';
import { Link as ScrollLink, Element } from 'react-scroll';

const Discover = () => {
  const [expanded, setExpanded] = useState({});

  const handleReadMore = (section) => {
    setExpanded((prev) => {
      return { ...prev, [section]: true };
    });
  };

  return (
    <>
      <AppBar
        position='sticky'
        sx={{
          width: { xs: '100%', md: '60%', lg: '40%' },
          color: '#fff',
          bgcolor: '#cb351a',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          margin: '0 auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            height: '50px',
          }}
        >
          <ScrollLink
            to='venue'
            smooth={true}
            duration={500}
            spy={true}
            activeStyle={{ borderBottom: '2px solid #fff' }}
          >
            <Button
              color='inherit'
              sx={{ minHeight: 'auto', padding: '0 8px', lineHeight: '20px' }}
            >
              <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                Venue
              </Typography>
            </Button>
          </ScrollLink>
          <ScrollLink
            to='redRiver'
            smooth={true}
            duration={500}
            spy={true}
            activeStyle={{ borderBottom: '2px solid #fff' }}
          >
            <Button
              color='inherit'
              sx={{ minHeight: 'auto', padding: '0 8px', lineHeight: '20px' }}
            >
              <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                Historic Downtown
              </Typography>
            </Button>
          </ScrollLink>
          <ScrollLink
            to='moreToDo'
            smooth={true}
            duration={500}
            spy={true}
            activeStyle={{ borderBottom: '2px solid #fff' }}
          >
            <Button
              color='inherit'
              sx={{ minHeight: 'auto', padding: '0 8px', lineHeight: '20px' }}
            >
              <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                More to Do
              </Typography>
            </Button>
          </ScrollLink>
        </Box>
      </AppBar>
      <Grid
        container
        justifyContent='center'
        // alignItems='center'
        spacing={3}
        sx={{ mt: 1 }}
      >
        {/* First Row: Logo and Text */}
        <Element name='venue' />
        <Grid
          container
          item
          xs={12}
          alignItems='center'
          justifyContent='center'
          spacing={3}
          sx={{ mt: 1 }}
        >
          <Grid
            item
            container
            xs={12}
            md={4}
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <Grid item xs={12} sx={{ textAlign: 'center', p: 4 }}>
              <img
                src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/scc_logo.png'
                alt='Shreveport Convention Center'
                style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  position: 'relative',
                  maxHeight: { xs: expanded['main'] ? 'none' : '150px', md: 'none' },
                  overflow: 'hidden',
                  '&:after': !expanded['main']
                    ? {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: { xs: '75%', md: '90%' },
                        background: {
                          xs: 'linear-gradient(to bottom, transparent, white 60%)',
                          md: 'linear-gradient(to bottom, transparent, white 25%)',
                        },
                      }
                    : {},
                }}
              >
                <Typography
                  variant='body1'
                  sx={{
                    fontFamily: 'League Spartan',
                    lineHeight: '1.9',
                  }}
                  textAlign='justify'
                >
                  Opened in 2006, the Shreveport Convention Center was developed to revitalize
                  downtown Shreveport and boost the local economy by attracting large conventions
                  and events. Strategically located near the Red River and adjacent to the
                  Shreveport Hilton Hotel, the center offers over 350,000 square feet of modern,
                  flexible event space. It has hosted a wide range of events, from national
                  conferences to local gatherings, significantly contributing to local businesses
                  and economic activity. Over the years, it has undergone various upgrades to
                  maintain its appeal and competitive edge, solidifying Shreveport's position as a
                  key destination for business and leisure travel in the southeastern United States.
                </Typography>
                {!expanded['main'] && (
                  <ButtonBase
                    onClick={() => handleReadMore('main')}
                    sx={{
                      position: 'absolute',
                      top: { xs: '85%', md: '50%' },
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      color: '#cb351a',
                      gap: 1,
                      zIndex: 1,
                      whiteSpace: 'pre',
                    }}
                  >
                    <Typography fontSize='1.5rem' fontFamily='League Spartan'>
                      Read More
                    </Typography>
                    <ReadMore fontSize='medium' sx={{ mb: 0.5 }} />
                  </ButtonBase>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8} sx={{ mt: { sx: 0, md: 2 } }}>
            <img
              src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/venue_scc.jpg'
              alt='Shreveport Convention Center'
              style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '8px' }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} align='center'>
          <Divider sx={{ my: { xs: 0, md: 6 }, width: '80%' }} />
        </Grid>
        <Element name='redRiver' />
        {/* Second Row */}
        <Grid item xs={12}>
          <Typography
            variant='h4'
            align='left'
            color='#cb351a'
            sx={{
              fontFamily: 'League Spartan',
              fontWeight: '500',
              textAlign: { xs: 'center', md: 'left' },
              px: { xs: 2, md: 0 },
              pt: { xs: 4, md: 0 },
            }}
          >
            Why Rendezvous on the "Red"?
          </Typography>
        </Grid>
        {/* Third Row */}
        <Grid container item xs={12} spacing={3} justifyContent='center' alignItems='center'>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                borderRadius: '8px',
                overflow: 'hidden',
              }}
            >
              <Box sx={{ height: { xs: '100%', md: '350px' } }}>
                <img
                  src={banner}
                  alt='Red River'
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  width: '100%',
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  textAlign: 'center',
                  p: '5px 0',
                  boxSizing: 'border-box',
                }}
              >
                <Typography
                  variant='caption'
                  sx={{
                    fontFamily: 'League Spartan',
                    fontSize: '1.2rem',
                    display: 'block',
                    lineHeight: 1.2,
                  }}
                >
                  Red River - Shreveport, Louisiana
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant='h5'
              gutterBottom
              sx={{
                fontFamily: 'League Spartan',
                fontWeight: '500',
                textAlign: { xs: 'center', md: 'left' },
                fontSize: '1.5rem',
              }}
            >
              The Historic Red River
            </Typography>
            <Box
              sx={{
                position: 'relative',
                maxHeight: { xs: expanded['historic'] ? 'none' : '150px', md: 'none' },
                overflow: 'hidden',
                '&:after': !expanded['historic']
                  ? {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      height: { xs: '75%', md: '90%' },
                      background: {
                        xs: 'linear-gradient(to bottom, transparent, white 60%)',
                        md: 'linear-gradient(to bottom, transparent, white 25%)',
                      },
                    }
                  : {},
              }}
            >
              <Typography
                variant='body1'
                paragraph
                sx={{
                  fontFamily: 'League Spartan',
                  lineHeight: '1.9',
                }}
                textAlign='justify'
              >
                The Red River has been integral to Shreveport's history and development. Flowing
                from New Mexico through Texas and into Louisiana, the river provided a critical
                transportation route in the 19th century, enabling Shreveport to flourish as a
                commercial hub. During the steamboat era, it facilitated the movement of cotton,
                timber, and other goods, linking Shreveport to New Orleans and other key markets.
              </Typography>
              <Typography
                variant='body1'
                sx={{
                  fontFamily: 'League Spartan',
                  lineHeight: '1.9',
                  textIndent: '1.5em',
                }}
                textAlign='justify'
              >
                The river also holds historical military significance. During the Civil War,
                Shreveport's strategic position on the Red River made it a Confederate stronghold
                and supply depot, famously known as the "Last Capital of the Confederacy." Beyond
                commerce and conflict, the Red River has influenced the cultural and social life of
                Shreveport's residents, serving as a source of sustenance and recreation. Today, it
                remains a defining feature of the city, reflecting its rich historical legacy and
                enduring regional importance.
              </Typography>
              {!expanded['historic'] && (
                <ButtonBase
                  onClick={() => handleReadMore('historic')}
                  sx={{
                    position: 'absolute',
                    top: { xs: '85%', md: '50%' },
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#cb351a',
                    gap: 1,
                    zIndex: 1,
                    whiteSpace: 'pre',
                  }}
                >
                  <Typography fontSize='1.5rem' fontFamily='League Spartan'>
                    Read More
                  </Typography>
                  <ReadMore fontSize='medium' sx={{ mb: 0.5 }} />
                </ButtonBase>
              )}
            </Box>
          </Grid>
        </Grid>
        {/* Second Row */}
        <Grid item xs={12} align='center'>
          <Divider sx={{ mt: { xs: 0, md: 6 }, width: '80%' }} />
        </Grid>
        {/* Third Row */}
        <Grid
          container
          item
          xs={12}
          spacing={3}
          justifyContent='center'
          alignItems='flex-start'
          sx={{ mt: { xs: 0, md: 4 } }}
        >
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant='h4'
              align='left'
              color='#cb351a'
              sx={{
                fontFamily: 'League Spartan',
                fontWeight: '500',
                textAlign: { xs: 'center', md: 'left' },
                px: { xs: 2, md: 0 },
                pt: { xs: 1, md: 0 },
                mb: { xs: 3, md: 4 },
              }}
            >
              Downtown Roots
            </Typography>
            <Typography
              variant='h5'
              gutterBottom
              sx={{
                fontFamily: 'League Spartan',
                fontWeight: '500',
                textAlign: { xs: 'center', md: 'left' },
                fontSize: '1.5rem',
              }}
            >
              The Journey Begins Here
            </Typography>
            <Box
              sx={{
                position: 'relative',
                maxHeight: { xs: expanded['journey'] ? 'none' : '150px', md: 'none' },
                overflow: 'hidden',
                '&:after': !expanded['journey']
                  ? {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      height: { xs: '75%', md: '90%' },
                      background: {
                        xs: 'linear-gradient(to bottom, transparent, white 60%)',
                        md: 'linear-gradient(to bottom, transparent, white 25%)',
                      },
                    }
                  : {},
              }}
            >
              <Typography
                variant='body1'
                paragraph
                sx={{
                  fontFamily: 'League Spartan',
                  lineHeight: '1.9',
                }}
                textAlign='justify'
              >
                Sports South's journey began in the heart of Shreveport's downtown district in 1841,
                when two Welsh immigrants opened an apothecary shop. From these humble beginnings,
                they provided essential supplies to the pioneering community, including firearms and
                ammunition for farmers, soldiers, and lawmen. It business quickly became a vital
                part of the community, serving as a key supplier of essential goods and a hub for
                support and resources. This early establishment embodied the spirit of integrity,
                hard work, and dedication, values that resonated deeply with Shreveport's pioneers
                and laid the foundation for a lasting legacy.
              </Typography>
              <Typography
                variant='body1'
                sx={{
                  fontFamily: 'League Spartan',
                  lineHeight: '1.9',
                  textIndent: '1.5em',
                }}
                textAlign='justify'
              >
                Though Sports South is no longer at its original downtown location, the legacy of
                those early days continues to shape the company. The principles of integrity,
                family, tradition, and drive, established over 183 years ago, remain at the core of
                its operations. Today, as the nation's oldest and largest distributor of firearms,
                ammunition, and accessories, Sports South honors its rich history by maintaining a
                relentless pursuit of innovation and excellence. The company's commitment to
                providing unmatched distribution services and fostering a culture of dedication and
                hands-on involvement continues to reflect the values and vision of its founders.
              </Typography>
              {!expanded['journey'] && (
                <ButtonBase
                  onClick={() => handleReadMore('journey')}
                  sx={{
                    position: 'absolute',
                    top: { xs: '85%', md: '50%' },
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#cb351a',
                    gap: 1,
                    zIndex: 1,
                    whiteSpace: 'pre',
                  }}
                >
                  <Typography fontSize='1.5rem' fontFamily='League Spartan'>
                    Read More
                  </Typography>
                  <ReadMore fontSize='medium' sx={{ mb: 0.5 }} />
                </ButtonBase>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                borderRadius: '8px',
                overflow: 'hidden',
              }}
            >
              <Box sx={{ height: { xs: '100%', md: '500px' } }}>
                <img
                  src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/downtown_image.jpg'
                  alt='Downtown Shreveport'
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  width: '100%',
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  textAlign: 'center',
                  p: '5px 0',
                  boxSizing: 'border-box',
                }}
              >
                <Typography
                  variant='caption'
                  sx={{
                    fontFamily: 'League Spartan',
                    fontSize: '1.2rem',
                    display: 'block',
                    lineHeight: 1.2,
                  }}
                >
                  J.W. Morris & Co. circa 1841
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* Second Row */}
        <Grid item xs={12} align='center'>
          <Divider sx={{ my: { xs: 0, md: 6 }, width: '80%' }} />
        </Grid>
        <Element name='moreToDo' />
        <Grid item xs={12}>
          <Typography
            variant='h4'
            align='left'
            color='#cb351a'
            sx={{
              fontFamily: 'League Spartan',
              fontWeight: '500',
              textAlign: { xs: 'center', md: 'left' },
              px: { xs: 2, md: 0 },
              pt: { xs: 4, md: 0 },
              mb: { md: -4 },
            }}
          >
            More to Do in Shreveport
          </Typography>
        </Grid>
        {/* Third Row */}
        <Grid container item xs={12} spacing={3} justifyContent='center' alignItems='center'>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant='h5'
              gutterBottom
              sx={{
                fontFamily: 'League Spartan',
                fontWeight: '500',
                textAlign: { xs: 'center', md: 'left' },
                fontSize: '1.5rem',
              }}
            >
              Experience the Riverboat Casinos
            </Typography>
            <Box
              sx={{
                position: 'relative',
                maxHeight: { xs: expanded['experience'] ? 'none' : '150px', md: 'none' },
                overflow: 'hidden',
                '&:after': !expanded['experience']
                  ? {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      height: { xs: '75%', md: '90%' },
                      background: {
                        xs: 'linear-gradient(to bottom, transparent, white 60%)',
                        md: 'linear-gradient(to bottom, transparent, white 25%)',
                      },
                    }
                  : {},
              }}
            >
              <Typography
                variant='body1'
                paragraph
                sx={{
                  fontFamily: 'League Spartan',
                  lineHeight: '1.9',
                }}
                textAlign='justify'
              >
                The Shreveport/Bossier City area is known for its riverboat casinos, offering a
                thrilling experience on the water. Popular casinos like Margaritaville, Horseshoe,
                Bally's, and LIVE! provide a mix of gaming options, from slot machines to poker
                tables, along with dining, live entertainment, and beautiful views of the Red River.
                Whether you're a seasoned gambler or just looking for a fun night out, the riverboat
                casinos add an exciting dimension to your visit.
              </Typography>
              <Typography
                variant='body1'
                sx={{
                  fontFamily: 'League Spartan',
                  lineHeight: '1.9',
                  textIndent: '1.5em',
                }}
                textAlign='justify'
              >
                Caesar's Horseshoe in Bossier City, steeped in tradition since its establishment,
                offers a rich blend of classic gaming and timeless luxury. Renowned for its historic
                charm and enduring legacy, it continues to be a premier destination for those
                seeking an elegant and nostalgic casino experience.
              </Typography>
              {!expanded['experience'] && (
                <ButtonBase
                  onClick={() => handleReadMore('experience')}
                  sx={{
                    position: 'absolute',
                    top: { xs: '85%', md: '50%' },
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#cb351a',
                    gap: 1,
                    zIndex: 1,
                    whiteSpace: 'pre',
                  }}
                >
                  <Typography fontSize='1.5rem' fontFamily='League Spartan'>
                    Read More
                  </Typography>
                  <ReadMore fontSize='medium' sx={{ mb: 0.5 }} />
                </ButtonBase>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                borderRadius: '8px',
                overflow: 'hidden',
              }}
            >
              <Box sx={{ height: { xs: '100%', md: '350px' } }}>
                <img
                  src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/venue/Casino.jpg'
                  alt='Horseshoe Bossier City'
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  width: '100%',
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  textAlign: 'center',
                  p: '5px 0',
                  boxSizing: 'border-box',
                }}
              >
                <Typography
                  variant='caption'
                  sx={{
                    fontFamily: 'League Spartan',
                    fontSize: '1.2rem',
                    display: 'block',
                    lineHeight: 1.2,
                  }}
                >
                  Horseshoe Bossier City
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Fourth Row */}
        <Grid
          container
          item
          justifyContent='center'
          alignItems='center'
          xs={12}
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid
            container
            item
            xs={12}
            spacing={3}
            justifyContent='center'
            alignItems='center'
            sx={{ mb: { xs: 0, md: 15 } }}
          >
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
                onClick={() => window.open('https://www.shreveportaquarium.com/', '_blank')}
              >
                <img
                  src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/venue/Aquarium.jpg'
                  alt='Shreveport Aquarium'
                  style={{ width: '100%', height: '200px', objectFit: 'cover', display: 'block' }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    textAlign: 'center',
                    p: '5px 0',
                    boxSizing: 'border-box',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant='caption'
                    sx={{
                      fontFamily: 'League Spartan',
                      fontSize: '1.2rem',
                      display: 'block',
                      lineHeight: 1.2,
                    }}
                  >
                    Shreveport Aquarium
                  </Typography>
                  <OpenInNew sx={{ ml: 1 }} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
                onClick={() => window.open('https://www.rose.org/visit-public-gardens', '_blank')}
              >
                <img
                  src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/venue/AmericanRose.jpg'
                  alt='American Rose Center'
                  style={{ width: '100%', height: '200px', objectFit: 'cover', display: 'block' }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    textAlign: 'center',
                    p: '5px 0',
                    boxSizing: 'border-box',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant='caption'
                    sx={{
                      fontFamily: 'League Spartan',
                      fontSize: '1.2rem',
                      display: 'block',
                      lineHeight: 1.2,
                    }}
                  >
                    American Rose Center
                  </Typography>
                  <OpenInNew sx={{ ml: 1 }} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  window.open('https://www.facebook.com/RedRiverEntertainmentDistrict/', '_blank')
                }
              >
                <img
                  src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/venue/RedRiverDisctrict.jpg'
                  alt='Red River District'
                  style={{ width: '100%', height: '200px', objectFit: 'cover', display: 'block' }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    textAlign: 'center',
                    p: '5px 0',
                    boxSizing: 'border-box',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant='caption'
                    sx={{
                      fontFamily: 'League Spartan',
                      fontSize: '1.2rem',
                      display: 'block',
                      lineHeight: 1.2,
                    }}
                  >
                    Red River District
                  </Typography>
                  <OpenInNew sx={{ ml: 1 }} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
                onClick={() => window.open('http://www.rwnaf.org/', '_blank')}
              >
                <img
                  src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/venue/NortonArt.jpg'
                  alt='R.W. Norton Art Gallery'
                  style={{ width: '100%', height: '200px', objectFit: 'cover', display: 'block' }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    textAlign: 'center',
                    p: '5px 0',
                    boxSizing: 'border-box',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant='caption'
                    sx={{
                      fontFamily: 'League Spartan',
                      fontSize: '1.2rem',
                      display: 'block',
                      lineHeight: 1.2,
                    }}
                  >
                    R.W. Norton Art Gallery
                  </Typography>
                  <OpenInNew sx={{ ml: 1 }} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Discover;
