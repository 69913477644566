import React, { useState } from 'react';
import { Alert, Box, Grid, List, ListItem, TextField, Typography } from '@mui/material';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { setExhibitorRegistration } from '../../../Redux/ExhibitorRegistrationSlice';

const ExhibitorInfo = () => {
  const [companyNameTouched, setCompanyNameTouched] = useState(false);
  const dispatch = useDispatch();
  const registration = useSelector((state) => state.registration);

  const handleNameChange = (e) => {
    dispatch(setExhibitorRegistration({ companyName: e.target.value }));
  };

  const handlePhoneNumberChange = (e) => {
    const rawPhoneNumber = e.target.value.replace(/\D/g, '');
    dispatch(setExhibitorRegistration({ companyPhone: rawPhoneNumber, companyPhoneError: false }));
  };

  const handleEmailChange = (e) => {
    dispatch(setExhibitorRegistration({ companyEmail: e.target.value, companyEmailError: false }));
  };

  const handleWebsiteChange = (e) => {
    dispatch(setExhibitorRegistration({ companyWebsite: e.target.value }));
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return phoneNumber;
    const phoneNumberDigits = phoneNumber.replace(/\D/g, '');
    const phoneNumberLength = phoneNumberDigits.length;
    if (phoneNumberLength < 4) return phoneNumberDigits;
    if (phoneNumberLength < 7) {
      return `(${phoneNumberDigits.slice(0, 3)}) ${phoneNumberDigits.slice(3)}`;
    }
    return `(${phoneNumberDigits.slice(0, 3)}) ${phoneNumberDigits.slice(
      3,
      6
    )}-${phoneNumberDigits.slice(6, 10)}`;
  };

  const formatPrice = (price) => {
    return price.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  const handleNameBlur = () => {
    setCompanyNameTouched(true);
  };

  const handleEmailBlur = (e) => {
    !validator.isEmail(e.target.value)
      ? dispatch(setExhibitorRegistration({ companyEmailError: true }))
      : dispatch(setExhibitorRegistration({ companyEmailError: false }));
  };

  const handlePhoneBlur = (e) => {
    !validator.isMobilePhone(e.target.value)
      ? dispatch(setExhibitorRegistration({ companyPhoneError: true }))
      : dispatch(setExhibitorRegistration({ companyPhoneError: false }));
  };

  return (
    <Grid container spacing={2}>
      {registration.boothNumber && (
        <Grid container item xs={12} md={12} justifyContent='center'>
          <Grid item xs={12} md={6}>
            <Box sx={{ marginBottom: 3 }}>
              <Typography
                variant='h6'
                sx={{ fontWeight: 'bold', color: '#00325a', marginBottom: 1 }}
              >
                Booth Selection
              </Typography>
              <List disablePadding>
                <ListItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    py: 1.5,
                    px: 2,
                    backgroundColor: '#fff',
                    borderRadius: 2,
                    mb: 1,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Box>
                    <Typography
                      variant='body1'
                      sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                    >
                      Booth: {registration.boothNumber}
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                      Type: {registration.boothType}
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                  >
                    {`$${formatPrice(Number(registration.boothPrice))}`}
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label='Exhibitor Company Name'
          value={registration.companyName}
          onChange={handleNameChange}
          onBlur={handleNameBlur}
          error={companyNameTouched && registration.companyName === ''}
          helperText={
            companyNameTouched && registration.companyName === ''
              ? 'Company name is required.'
              : ' '
          }
          required
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label='Company Phone Number'
          value={formatPhoneNumber(registration.companyPhone)}
          onChange={handlePhoneNumberChange}
          onBlur={handlePhoneBlur}
          error={registration.companyPhoneError}
          helperText={registration.companyPhoneError ? 'Please enter a valid phone number.' : ' '}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label='Company Email'
          value={registration.companyEmail}
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          error={registration.companyEmailError}
          helperText={registration.companyEmailError ? 'Please enter a valid email address.' : ' '}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label='Company Website'
          value={registration.companyWebsite}
          onChange={handleWebsiteChange}
          helperText=' '
        />
      </Grid>
      <Grid item xs={12}>
        <Alert severity='info' sx={{ justifyContent: 'center' }}>
          This information will be publicly visible.
        </Alert>
      </Grid>
    </Grid>
  );
};

export default ExhibitorInfo;
