import { Box } from '@mui/material';

const EmbeddedVideo = ({ src, onLoad = () => {}, onError = () => {} }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        paddingBottom: '56.25%',
        height: 0,
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <iframe
        src={src}
        title='Video player'
        allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        referrerPolicy='strict-origin-when-cross-origin'
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 0,
        }}
        onLoad={onLoad}
        onError={onError}
      />
    </Box>
  );
};

export default EmbeddedVideo;
