import React, { useEffect, useState } from 'react';
import { Modal, Typography, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '../../Redux/ModalSlice';

const BadgePreviewModal = ({
  firstName = '',
  lastName = '',
  jobTitle = '',
  companyName = '',
  city = '',
  state = '',
}) => {
  const [imageSize, setImageSize] = useState({ width: 'auto', height: 'auto' });
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.modalStatus.badgeModal);

  useEffect(() => {
    const img = new Image();
    img.src = 'https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/badge_background.jpg';
    img.onload = () => {
      setImageSize({ width: img.width, height: img.height });
    };
  }, []);

  return (
    <Modal
      open={isModalOpen}
      onClose={() => dispatch(setModalStatus({ badgeModal: false }))}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
      >
        <Paper
          style={{
            position: 'relative',
            width: imageSize.width,
            height: imageSize.height,
            backgroundImage: `url('https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/badge_background.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            textAlign: 'center',
            color: 'black',
          }}
        >
          <Typography
            variant='h3'
            sx={{
              position: 'absolute',
              top: '60%',
              width: '100%',
              transform: 'translateY(-50%)',
              fontWeight: 'bold',
            }}
          >
            {firstName}
          </Typography>
          <Typography
            variant='h4'
            sx={{
              position: 'absolute',
              top: '70%',
              width: '100%',
              transform: 'translateY(-50%)',
            }}
          >
            {lastName}
          </Typography>
          <Typography
            variant='h6'
            sx={{
              position: 'absolute',
              top: '80%',
              width: '100%',
              transform: 'translateY(-50%)',
              fontWeight: 'bold',
            }}
          >
            {companyName}
          </Typography>
          <Typography
            variant='body1'
            sx={{
              position: 'absolute',
              top: '85%',
              width: '100%',
              transform: 'translateY(-50%)',
            }}
          >
            {jobTitle}
          </Typography>
          <Typography
            variant='body2'
            sx={{
              position: 'absolute',
              top: '90%',
              width: '100%',
              transform: 'translateY(-50%)',
            }}
          >
            {city && state
              ? `${city}, ${state}`
              : city && !state
              ? city
              : state && !city
              ? state
              : ''}
          </Typography>
        </Paper>
      </motion.div>
    </Modal>
  );
};

export default BadgePreviewModal;
